import { useState } from "react";
import CustomPopup from "./CustomPopup";
import { updateIp } from "../api";

const IpConfirmation = (props) => {
  const [networkSuggestion, setNetworkSuggestion] = useState(false);
  const [error, setError] = useState(false);
  const notHomeNetwork = (e) => {
    setNetworkSuggestion(true);
    props.onNotConnected();
  };

  const assignNewIp = (e) => {
    updateIp()
      .then((response) => {
        props.onUpdateStatus(true);
      })
      .catch((error) => {
        setError(true);
      });
  };
  const handleOnClose = () => {
    props.onUpdateStatus(false);
  };
  return (
    <div>
      <CustomPopup show={true} onClose={handleOnClose}>
        <div>
          <div>
            <h1 className="heading-52">Alert</h1>
            <div className="text-block-161">
              Please confirm that you are connected to your home Wi-Fi network
              in order to complete this action
            </div>
          </div>
          <div className="div-block-48">
            <button onClick={assignNewIp} className="button-20 w-button">
              Yes, I am connected to my home Wi-Fi network
            </button>
            <button onClick={notHomeNetwork} className="button-21 w-button">
              No, I am not connected to my home Wi-Fi network
            </button>
            {error && (
              <div className="w-form-fail">
                Oops! Something went wrong while submitting.
              </div>
            )}
            <div className="text-block-161">
              <br></br>
              {networkSuggestion === true && (
                <p>Please connect to your home wifi network and try again!</p>
              )}
            </div>
          </div>
        </div>
      </CustomPopup>
    </div>
  );
};

export default IpConfirmation;
