import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { is_user_active } from "../common/Utils";

function Confirmation({ currentUser }) {
  const navigate = useNavigate();
  useEffect(() => {
    // If the user is not active navigate to appropriate page else execute the confirmation page

    if (!is_user_active(currentUser)) {
      navigate("/login");
    }
  }, [currentUser]);
  return (
    <div className="section-4 wf-section">
      <h1 className="heading-22">Congratulations! You are all set up</h1>
      <h3 className="heading-20">
        Please make sure to bookmark{" "}
        <strong className="bold-text-124">https://kavalan.app</strong> in your
        browser
      </h3>
      <div className="text-block-43">
        If you completed your router or device set up correctly, Kavalan will
        start protecting your devices in just a few minutes and you will see
        data on your dashboard.
        <br />
      </div>
      <button
        onClick={() => navigate("/dashboard")}
        className="kavalan-button w-button"
      >
        TAKE ME TO MY DASHBOARD
      </button>
    </div>
  );
}

export default Confirmation;
