import React from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#8A8E88" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  items: "center",
}));

function AdvancedInsightsCard(props) {
  const navigate = useNavigate();
  const privacyRiskreport = () => navigate("/privacy-risk-report");
  const privacyScan = () => navigate("/privacy-scan");
  const deepPrivacyAnalysis = () => navigate("/deep-privacy-analysis");

  return (
    <div className="custom-card third-row">
      <div className="custom-card-title">Ask Kavalan</div>
      <div className="actions">
        <div className="button-div">
          <div className="button-div-title">Scan a Site</div>
          <Item
            sx={{ border: 1, borderColor: "#C1BEBE" }}
            elevation={3}
            className={`button-div-icon ${
              props.isNotKavalan ? "disabled" : ""
            }`}
            onClick={privacyScan}
          >
            <img
              src="images/icons_9_bold.png"
              loading="lazy"
              alt=""
              className="image-icon"
            />
          </Item>
        </div>

        <div className="vertical-divider"></div>
        <div className="button-div">
          <div className="button-div-title">Data Exposure Report</div>
          <Item
            sx={{ border: 1, borderColor: "#C1BEBE" }}
            elevation={3}
            className={`button-div-icon ${
              props.isNotKavalan ? "disabled" : ""
            }`}
            onClick={deepPrivacyAnalysis}
          >
            <img
              src="images/icons_12_bold.png"
              loading="lazy"
              alt=""
              className="image-icon"
            />
          </Item>
        </div>
        <div className="vertical-divider"></div>
        <div className="button-div">
          <div className="button-div-title">Privacy Risk Report</div>
          <Item
            sx={{ border: 1, borderColor: "#C1BEBE" }}
            elevation={3}
            className={`button-div-icon ${
              props.isNotKavalan ? "disabled" : ""
            }`}
            onClick={privacyRiskreport}
          >
            <img
              src="images/icons_6_bold.png"
              loading="lazy"
              alt=""
              className="image-icon"
            />
          </Item>
        </div>
      </div>
    </div>
  );
}

export default AdvancedInsightsCard;
