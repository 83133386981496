import React from "react";

function Footer() {
  return (
    <div>
      <div className="section-3 wf-section">
        <div className="w-row">
          <div className="w-col w-col-3"></div>
          <div className="column-45 w-col w-col-6">
            <img
              src="images/NANDI-security.png"
              loading="lazy"
              width="802"
              sizes="(max-width: 479px) 48vw, (max-width: 767px) 49vw, (max-width: 991px) 24vw, 25vw"
              alt=""
              className="image-15"
            />
          </div>
          <div className="w-col w-col-3"></div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
