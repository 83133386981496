import React, { useState, useCallback } from "react";
//eslint-disable-next-line
import { useNavigate, Link } from "react-router-dom";

import CustomPopup from "../common/CustomPopup";
import IpConfirmation from "../common/IpConfirmation";
import { deactivateUser } from "../api";

function Profile({ currentUser }) {
  const [showPopup, setShowPopup] = useState(false);
  const [cancelSubPopup, setCancelSubPopup] = useState(false);
  const [error, setError] = useState(false);
  const [reloadPage, setReloadPage] = useState(false); //eslint-disable-line

  function pascalCase(str) {
    if (str !== undefined) {
      return str
        .split(" ")
        .reduce(
          (s, c) => s + "" + (c.charAt(0).toUpperCase() + c.slice(1) + " "),
          "",
        );
    }
    return null;
  }

  const updateStatus = useCallback((state) => {
    if (state) {
      // window.location.reload();
      setReloadPage(true); //suggested as per coderabbit
    }
    setShowPopup(false);
  }, []);

  const notHomeNetwork = useCallback(() => {
    setTimeout(() => {
      setShowPopup(false);
    }, 15000);
  }, []);

  // Unused Function delete after 12/31/2024
  // function update_subscription() {
  //   navigate("/product", { state: { upgrade: true } });
  // }

  const cancelSubscription = () => {
    deactivateUser()
      .then((response) => {
        if (response) {
          window.location.reload();
        }
        setCancelSubPopup(false);
      })
      .catch((error) => {
        setError(error.message);
      });
  };
  const closeCancelSubscription = () => {
    setCancelSubPopup(false);
  };
  const CancelSubscriptionLayout = () => {
    return (
      <div>
        <CustomPopup show={true} onClose={closeCancelSubscription}>
          <div>
            <div>
              <h1 className="heading-52">Alert</h1>
              <div className="text-block-161">
                Are you sure you want to cancel the subscription
              </div>
            </div>
            <div className="div-block-48-confirm">
              <button
                type="button"
                onClick={cancelSubscription}
                className="button-21-confirm w-button"
              >
                Yes
              </button>
              <button
                type="button"
                onClick={closeCancelSubscription}
                className="button-20-confirm w-button"
              >
                No
              </button>
              {error && (
                <div className="w-form-fail">
                  Oops! Something went wrong while submitting.
                </div>
              )}
            </div>
          </div>
        </CustomPopup>
      </div>
    );
  };

  return (
    <div>
      {" "}
      <div className="customer-account-information-section wf-section">
        <div className="container-2 w-container">
          <h1 className="heading-44">Your Account Information</h1>
          <div className="columns-53 w-row">
            <div className="column-110 w-col w-col-6 w-col-tiny-tiny-stack">
              <div className="div-block-44">
                <h3 className="heading-45">
                  <strong className="bold-text-93">
                    {" "}
                    Subscription Package:{" "}
                  </strong>
                  <div className="profile-vals">
                    {" "}
                    {currentUser.productLabel ?? "N/A"}{" "}
                  </div>
                </h3>
                <h3 className="heading-46">
                  <strong className="bold-text-93">
                    Registered IP Address:{" "}
                  </strong>
                  <div className="profile-vals">
                    {currentUser.serviceIpAddress ?? "N/A"}
                  </div>
                </h3>
                <h3 className="heading-46">
                  <strong className="bold-text-93">Public IP Address: </strong>
                  <div className="profile-vals">
                    {currentUser.publicIpAddress ?? "N/A"}
                  </div>
                </h3>
                <h3 className="heading-47">
                  <strong className="bold-text-93">Subscription Plan: </strong>
                  <div className="profile-vals">
                    {pascalCase(currentUser.subscriptionPlan) ?? "N/A"}
                  </div>
                </h3>
              </div>
            </div>
            {currentUser.subscribed && (
              <div className="column-111 w-col w-col-6 w-col-tiny-tiny-stack">
                <div className="div-block-46">
                  <h3 className="heading-48">Account Actions</h3>
                  <button
                    className="button-26-new  w-button"
                    onClick={(e) => setCancelSubPopup(true)}
                  >
                    {" "}
                    Cancel Subscription
                  </button>
                  {/*
                  <h3 className="heading-48">Update Subscription</h3>
                  <button className="button-large w-button" onClick={update_subscription}>  Update Subscription</button>*/}
                  {currentUser.serviceIpAddress !==
                    currentUser.publicIpAddress && (
                    <div className="div-block-46-reconnect">
                      {" "}
                      <h3 className="heading-48">
                        Click on 'Reconnect' to update your registered IP
                        Address{" "}
                      </h3>
                      <button
                        className="button-26-new w-button"
                        onClick={(e) => setShowPopup(true)}
                      >
                        Reconnect
                      </button>{" "}
                    </div>
                  )}
                </div>
              </div>
            )}
            {showPopup && (
              <IpConfirmation
                onUpdateStatus={updateStatus}
                onNotConnected={notHomeNetwork}
              />
            )}
            {cancelSubPopup && <CancelSubscriptionLayout />}
          </div>
          <div className="columns-57 w-row">
            <div className="column-117 w-col w-col-6">
              <a href="https://www.getkavalan.com/tos" className="link-29">
                Terms of Service
              </a>
            </div>
            <div className="w-col w-col-6">
              <a href="https://www.getkavalan.com/privacy" className="link-28">
                Privacy Policy
              </a>
            </div>
          </div>
          <Link to="/dashboard" className="button-7 w-button">
            Back to Dashboard
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Profile;
