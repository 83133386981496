import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import { PiSpinner } from "react-icons/pi";
import { Container, Box, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

import { getDashboard } from "../api";
import ProductType from "../constants/Constants";
import { is_user_active } from "../common/Utils";
import {
  hasDashboardData,
  getDashboardData,
  setDashboardData,
  clearDashboardData,
  getLastUpdated,
  setLastUpdated,
  modifyDashboardDataToSession,
} from "../common/StorageUtils";

import "../css/new-dashboard.css";

import BubblesCard from "./Bubbles";
import MyDoughnut from "./Doughnut";
import BlockingActionsCard from "./BlockingActionsCard";
import AdvancedInsightsCard from "./AdvancedInsightsCard";
import PrivacyInsights from "./PrivacyInsights";
import InternetActivityInsights from "./InternetActivityInsights";
import BlockedInsights from "./BlockedInsights";
import RefreshButton from "./RefreshButton";
import TutorialButton from "./TutorialButton";
import { color } from "d3";

// Top level component for the dashboard
function Dashboard2(props) {
  // section 1. All state informtion is stored here.
  const [currentDate, setCurrentDate] = useState("");
  const [dashBoardDetails, setDashBoardDetails] = useState(null);
  const [appsCount, setappsCount] = useState(0);
  const [websitesCount, setwebsitesCount] = useState(0);
  const [visibility, setVisibility] = useState(false); //eslint-disable-line
  // const [ipsetconfirmation, setipsetconfirmation] = useState(false);
  const [isNotKavalan, setIsNotKavalan] = useState(true);
  const [showUpgradeText_, setShowUpgradeText_] = useState(false);
  const [error, setError] = useState(false); //eslint-disable-line
  const navigate = useNavigate();
  const [mainSpinner, setMainSpinner] = useState(false);
  // const [blocked, setBlocked] = useState("Blocked");
  // const [unBlocked, setUnblocked] = useState("Unblocked");

   const [adultSitesInitial, setAdultSitesInitial] = useState(null);

  // Section 2. All functions that modify the state are stored here.
  useEffect(() => {
    //window.scrollTo(0, 0);

    if (!hasDashboardData()) {
      queryAndStoreDashboardData();
    } else {
      set_Dashboard_Viewdata(getDashboardData());
      setCurrentDate(getLastUpdated());
    }
    window.addEventListener("unload", clearDashboardData); //it listens to the click on the "a href" element, but not navigate("/...")
  }, []);

  const set_Dashboard_Viewdata = (response) => {
    setDashBoardDetails(response?.metadata);
    if (props.product === ProductType.KAVALAN) {
      setappsCount(response?.custom?.noOfAppsBlocked);
      setwebsitesCount(response?.custom?.noOfWebsitesBlocked);
      setAdultSitesInitial(
        response?.custom?.adultSites ? "blocked" : "unblocked"
      );
      //setAdultSiteStatus(response?.custom?.adultSites); //true or false

      setShowUpgradeText_(false);
      setIsNotKavalan(false);
    } else if (
      props.product === ProductType.KAVALAN_LIGHT ||
      props.product === ProductType.TRIAL
    ) {
      setShowUpgradeText_(true);
      setIsNotKavalan(true);
    }
  };

  // Query, view and locally store dashboard data
  const queryAndStoreDashboardData = () => {
    setMainSpinner(true);

    let mounted = true;
    if (!is_user_active(props.currentUser)) {
      setMainSpinner(false);
      navigate("/login");
    } else {
      getDashboard()
        .then((response) => {
          // Store an JSON response to session
          setDashboardData(response);
          setMainSpinner(false);
          setLastUpdated(getDate());
          if (mounted) {
            if (response?.metadata?.ipChanged === true) {
              setVisibility(true);
            }
            set_Dashboard_Viewdata(response);
            setCurrentDate(getLastUpdated());
          }
        })
        .catch((error) => {
          // console.log("am i getting an error", error);
          setMainSpinner(false);
          setError(true);
        });
    }
    return () => (mounted = false);
  };

  // Section 3. All functions that read state but not modify state are here.

  const refreshData = () => {
    setError(false);
    clearDashboardData();
    queryAndStoreDashboardData();
  };

  // Section 4. All functions that are useful helpers/utils are here. Ex: getDate
  const getDate = () => {
    const currentDate = new Date();
    var year = currentDate.getFullYear(); //Current Year
    var month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); //Current Month
    var date = currentDate.getDate().toString().padStart(2, "0"); //Current Date
    var hours = currentDate.getHours().toString().padStart(2, "0"); //Current Hours
    var min = currentDate.getMinutes().toString().padStart(2, "0"); //Current Minutes
    var sec = currentDate.getSeconds().toString().padStart(2, "0"); //Current Seconds

    return (
      month + "/" + date + "/" + year + " " + hours + ":" + min + ":" + sec
    );
  };

  // Section 5 prefix - Functions related to jsx display
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    fontFamily: "Proximanova",
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const ShowUpgradeText = () => (
    <div>
      <hr className="hrstyle"></hr>
      <div className="upgrade-text">
        Upgrade to Kavalan Standard is required to access these additional
        features.
      </div>
    </div>
  );

  const ShowErrorText = () => (
    <div>
      <div className="text-error">
        <i
          class="fa-solid fa-triangle-exclamation"
          style={{ marginRight: "5px" }}
        ></i>
        Oops! There has been an error loading your latest data. Please try again
        later.
        <i
          class="fa-solid fa-triangle-exclamation"
          style={{ marginLeft: "5px" }}
        ></i>
      </div>
    </div>
  );
  // Section 5 - React Component
  return (
    <>
      <Container sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ width: "90%", mb: "20px" }}>
          <Grid container>
            <Grid
              item
              md={2}
              xs={6}
              order={{ xs: 2, md: 1 }}
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <button
                onClick={refreshData}
                style={{ backgroundColor: "transparent" }}
              >
                <RefreshButton />
              </button>
            </Grid>

            <Grid item md={8} xs={12} order={{ xs: 1, md: 2 }}>
              {error === true ? <ShowErrorText /> : ""}
              <div className="text-update">Last Update : {currentDate} </div>
            </Grid>

            <Grid
              item
              md={2}
              xs={6}
              order={3}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <button style={{ backgroundColor: "transparent" }}>
                <TutorialButton />
              </button>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            {/* // Section 5.1 Top Row */}
            <Grid item md={6} xs={12}>
              <Item sx={{ height: 270, justifyContent: "center" }}>
                {dashBoardDetails ? (
                  <MyDoughnut
                    mainSpinner={mainSpinner}
                    mode={props.mode}
                    totalWebDomains={dashBoardDetails.noOfDomainsInvoked}
                    accessAttemptsBlocked={dashBoardDetails.noOfDomainsBlocked}
                    maliciousDomainsBlocked={
                      dashBoardDetails.noOfUniqueMalwareDomainsBlocked
                    }
                  />
                ) : (
                  <PiSpinner
                    style={{
                      fontSize: "5rem",
                      animation: "spin 2s linear infinite",
                      marginTop: "80px",
                    }}
                  />
                )}
              </Item>
            </Grid>

            <Grid item md={6} xs={12}>
              <Item
                sx={{
                  height: { xs: "auto", md: 270 },
                  minHeight: { xs: 270 },
                }}
              >
                <BubblesCard
                  domainsBlocked={
                    dashBoardDetails != null
                      ? dashBoardDetails.domainsBlocked
                      : null
                  }
                  uniqueMalwareDomainsBlocked={
                    dashBoardDetails != null
                      ? dashBoardDetails.uniqueMalwareDomainsBlocked
                      : null
                  }
                  product={props.product}
                  mainSpinner={mainSpinner}
                />
              </Item>
            </Grid>
            {/* // Section 5.2 middle row */}
            <Grid item md={6} xs={12}>
              <Item>
                <PrivacyInsights
                  uniqueThreats={
                    dashBoardDetails != null
                      ? dashBoardDetails.noOfUniqueDomainsBlocked
                      : 0
                  }
                  accessAttemptsBlocked={
                    dashBoardDetails != null
                      ? dashBoardDetails.noOfDomainsBlocked
                      : 0
                  }
                  maliciousDomainsBlocked={
                    dashBoardDetails != null
                      ? dashBoardDetails.noOfUniqueMalwareDomainsBlocked
                      : 0
                  }
                  screenRecordingSites={
                    dashBoardDetails != null
                      ? dashBoardDetails.noOfScreenRecordingSites
                      : 0
                  }
                  keyLoggingSites={
                    dashBoardDetails != null
                      ? dashBoardDetails.noOfKeyLoggingSites
                      : 0
                  }
                  noPrivacyPolicyApps={
                    dashBoardDetails != null
                      ? dashBoardDetails.noOfNoPrivacyPolicyApps
                      : 0
                  }
                />
              </Item>
            </Grid>
            {/* // Section 5.3 bottom row */}
            <Grid item md={4} xs={8}>
              <Item>
                <InternetActivityInsights
                  uniqueDomainsInvoked={
                    dashBoardDetails != null
                      ? dashBoardDetails.noOfUniqueDomainsInvoked
                      : 0
                  }
                  domainsInvokedDuringBedTime={
                    dashBoardDetails != null
                      ? dashBoardDetails.noOfDomainsInvokedDuringBedTime
                      : 0
                  }
                  domainsInvoked={
                    dashBoardDetails != null
                      ? dashBoardDetails.noOfDomainsInvoked
                      : 0
                  }
                />
              </Item>
            </Grid>
            <Grid item md={2} xs={4}>
              <Item>
                <BlockedInsights
                  blockedApps={appsCount}
                  blockedDomains={websitesCount}
                />
              </Item>
            </Grid>
            {showUpgradeText_ ? (
              <Grid item xs={12}>
                <div className="showUpgradeText">
                  <ShowUpgradeText />
                </div>
              </Grid>
            ) : null}

            <Grid item md={6} xs={12}>
              <Item>
                <BlockingActionsCard
                  adultSitesInitial={adultSitesInitial}                  
                  setError={setError}
                  isNotKavalan={isNotKavalan}
                  modifyDashboardDataToSession={modifyDashboardDataToSession}
                  // blockSites={blockSites}
                />
              </Item>
            </Grid>
            <Grid item md={6} xs={12}>
              <Item>
                <AdvancedInsightsCard isNotKavalan={isNotKavalan} />
              </Item>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default Dashboard2;
