import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { is_user_active } from "../common/Utils";

function Home({ currentUser }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      redirect(currentUser);
    } else {
      navigate("/login");
    }
  }, [currentUser]);

  const redirect = (user) => {
    if (!user.registered) {
      navigate("/agreement");
    } else if (user.registered && !is_user_active(user)) {
      navigate("/product");
    } else {
      navigate("/dashboard");
    }
  };

  return <div>Routing .......</div>;
}

export default Home;
