import React, { useEffect, useState } from "react";
import { PiSpinner } from "react-icons/pi";
import { blockApps as blockAdultSites } from "../api";
import { useNavigate } from "react-router-dom";
import ColorSwitch from "./Switch";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#8A8E88" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  items: "center",
}));

export default function BlockingActionsCard(props) {
  const navigate = useNavigate();
  const blockApps = () => navigate("/blockapps");
  const blockWebsites = () => navigate("/blockwebsites");

  const [adultSiteStatus, setAdultSiteStatus] = useState("unblocked");

  useEffect(() => {
    if (!props.adultSitesInitial) return;
    setTimeout(() => setAdultSiteStatus(props.adultSitesInitial), 500);
  }, [props.adultSitesInitial]);

  const blockSites = (e) => {
    e.preventDefault();

    const blockAdultSwitchId = e.currentTarget.id;  
    let status = adultSiteStatus;
    const newStatusRequest = status === "unblocked" ? true : false;
    const newStatusTransit = status === "unblocked" ? "blocking" : "unblocking";
    setAdultSiteStatus(newStatusTransit);

    blockAdultSites(blockAdultSwitchId, newStatusRequest)
      .then((res) => {
        props.modifyDashboardDataToSession("adultSites", res.appStatus);
        setAdultSiteStatus(res.appStatus ? "blocked" : "unblocked");
      })
      .catch((error) => {
        props.setError(true);
        console.log("error", error);
      });
  };

  return (
    <div className="custom-card third-row">
      <div className="custom-card-title">Blocking Actions</div>
      <div className="actions">
        <div className="button-div">
          <div className="button-div-title">
            Block / Unblock <br /> an App
          </div>
          <Item
            sx={{ border: 1, borderColor: "#C1BEBE" }}
            elevation={3}
            className={`button-div-icon ${
              props.isNotKavalan ? "disabled" : ""
            }`}
            onClick={blockApps}
          >
            <img
              src="images/custom-apps-blocked_BK_bold.png"
              loading="lazy"
              alt=""
              className="image-icon"
            />
          </Item>
        </div>

        <div className="vertical-divider"></div>

        <div className="button-div">
          <div className="button-div-title">
            Block / Unblock <br /> a Web Domain
          </div>
          <Item
            sx={{ border: 1, borderColor: "#C1BEBE" }}
            elevation={3}
            className={`button-div-icon ${
              props.isNotKavalan ? "disabled" : ""
            }`}
            onClick={blockWebsites}
          >
            <img
              src="images/block-websites_BK_bold.png"
              loading="lazy"
              alt=""
              className="image-icon"
            />
          </Item>
        </div>

        <div className="vertical-divider"></div>

        <div className="button-div">
          <div className="button-div-title">
            Block / Unblock <br /> Adult Content
          </div>
          <div className={`${props.isNotKavalan ? "disabled" : ""}`}>
            <ColorSwitch
              checked={adultSiteStatus === "blocked"}
              onChange={blockSites}
              id="adultsites"
            />
            <div className="switch-label">
              {adultSiteStatus === "unblocking" ? (
                  <div className="pageload-spinner-small">
                    <PiSpinner
                      style={{
                        fontSize: "0.8rem",
                        animation: "spin 2s linear infinite",
                        marginTop: "-2px",
                      }}
                    />
                  </div>
                ) : (
                  <div className="block-unblock-label" style={{ color: "#E25506"}}>Unblocked</div>
                )
              }
              {                
                adultSiteStatus === "blocking" ? (
                  <div className="pageload-spinner-small">
                    <PiSpinner
                      style={{
                        fontSize: "0.8rem",
                        animation: "spin 2s linear infinite",
                        marginTop: "-2px",
                      }}
                    />
                  </div>
                ) : (
                  <div className="block-unblock-label" style={{ color: "#1E7C1E"}}>Blocked</div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
