import React from "react";

import {
  getTooltipsUniqueThreats,
  getTooltipsAccessAttempts,
  getTooltipsMalicious,
  getTooltipsScreenRecording,
  getTooltipsKeyStroke,
  getTooltipsAppsNoPrivacy,
  getTooltipsUniqueDomains,
  getTooltipsWebDomains,
  getTooltipsTotalWebDomains,
  getTooltipsAppsBlocked,
  getTooltipsWebDomainsBlocked,
  getTooltipsAdultSitesBlocked,
} from "../common/helperFunctions";

function Faq() {
  const generalFaqs = () => {
    return (
      <ul className="list-7">
        <li>
          <strong className="bold-text-65">What is Kavalan?</strong>
          <br />
          Kavalan is an online privacy and security product to protect the
          entire connected home. We aim to make the task of protecting your home
          and your connected devices against online threats as simple as signing
          up with a few simple clicks - no software to buy, no devices to
          install. Kavalan leverages the power of DNS technology to provide
          protection to all devices including laptops, desktops, smart speakers,
          televisions, smartphones, tablets and more!
        </li>
        <li className="list-item-3">
          <strong className="bold-text-65">
            How do I get help and support with Kavalan?
            <br />
          </strong>
          You can reach out to us at contact@nandisecurity.com at any time. You
          can also look up our contact information in the &#x27;Contacts&#x27;
          section of our website. We are here to help you and be successful in
          protecting your home from online privacy and security threats.{" "}
        </li>
        <li className="list-item-3">
          <strong className="bold-text-65">
            What does Kavalan protect my home against?
            <br />
          </strong>
          Kavalan protects your home from communicating with trackers, ad
          networks and other entities that attempt to track all of your online
          movements. Kavalan also provides protection against your home
          communicating with known malware sites
        </li>
        <li className="list-item-3">
          <strong className="bold-text-65">
            What happens at the end of the trial period?
            <br />
          </strong>
          If you are a user who signed up during our beta trials, Kavalan is
          being offered to you for free for one year. If you signed up for the
          free trial, you will have access to the service for 30 days
          <br />‍<br />
          If you choose to continue past the expiration of the trial and become
          a customer of ours, you can upgrade your subscription at{" "}
          <a
            href="https://www.getkavalan.com"
            target="_blank"
            rel="noreferrer"
            className="bold-text-103"
          >
            https://www.getkavalan.com
          </a>
          . No further setup needed.
          <br />‍<br />
          If you choose NOT to continue using Kavalan after the trial period,
          you will not be charged and we will disable the service for your home.
          You will need to remove Project Kavalan’s IP address as the ‘Primary
          DNS’ on your router setting and set another DNS provider as your
          ‘Primary DNS’.
        </li>
        <li className="list-item-3">
          <strong className="bold-text-65">
            Does Kavalan remove malware from my home network?
            <br />
          </strong>
          No, Kavalan identifies and blocks communication with malicious
          websites and known malware sites based on threat intelligence feeds
          that we subscribe to. It is not a malware removal service nor does it
          replace anti-virus software. Malware can get into your home from
          various other sources such as downloaded apps, USB sticks, exposure to
          external networks or even previous cyber attacks on the devices in
          your home.
        </li>
        <li className="list-item-3">
          <strong className="bold-text-65">
            Does Kavalan remove all internet ads?
            <br />
          </strong>
          Kavalan removes most kinds of internet ads such as banner ads, pop
          ups, etc. However, there are ads served by some networks such as
          Facebook, Google, etc that are inline ads which Kavalan does not
          remove. This is because they serve their ads as part of their service
          and stopping them will disrupt the service itself. However, in order
          to serve those ads, they watch your behavior across thousands of other
          internet properties and Kavalan blocks those collection attempts.{" "}
        </li>
        <li className="list-item-3">
          <strong className="bold-text-65">
            Does Project Kavalan remove paywalls?
            <br />
          </strong>
          No - Kavalan is not designed for nor intends to help users violate pay
          walls or subscription walls.
        </li>
        <li className="list-item-12">
          <strong className="bold-text-107">
            What do the bubble colors mean in my dashboard bubble chart?
            <br />
          </strong>
          Orange bubbles are privacy violating sites that Kavalan automatically
          blocked for you.
          <br />
          Red bubbles are malicious sites that Kavalan blocked. These may be
          malware, botnets, phishing, key loggers or more. <br />
          Kavalan Standard users who have enabled Adult Content Blocking, may
          see purple bubbles for any adult sites that may have been accessed and
          blocked.
          <br />
          For all bubble types, click on the bubble to learn more about the
          specific site that was blocked and the category.
        </li>
        <li className="list-item-13">
          <strong className="bold-text-108">
            How do I interpret the dashboard threat meter?
            <br />
          </strong>
          The threat meter is intended to give you a sense of the overall level
          of threat your home faced in the last 24 hours i.e. this is the level
          of threat that your home or small business would have been exposed to
          if you did NOT have Kavalan protection.
        </li>
        <li className="list-item-14">
          <strong className="bold-text-109">
            How do I interpret each of the metrics in my dashboard?
            <br />
          </strong>
          Very simple! Hover your mouse on top of the question mark icon and a
          pop up message will explain what exactly each metric is.
          <br />
          That being said, here is a quick explanation of each metric:
          <br />‍<br />
          <strong className="bold-text-110">
            - Unique Threats Blocked: <br />
          </strong>
          {getTooltipsUniqueThreats()}
          <br />‍<br />
          <strong className="bold-text-111">
            - Access Attempts Blocked: <br />
          </strong>
          {getTooltipsAccessAttempts()}
          <br />‍<br />
          <strong className="bold-text-112">
            - Malicious Domains Blocked: <br />
          </strong>
          {getTooltipsMalicious()}
          <br />‍<br />
          <strong className="bold-text-114">
            - Screen Recording Sites Detected:
            <br />
          </strong>
          {getTooltipsScreenRecording()}
          <br /> <br />
          <strong className="bold-text-114">
            - Key Stroke Logging Sites Detected:
            <br />
          </strong>
          {getTooltipsKeyStroke()}
          <br /> <br />
          <strong className="bold-text-114">
            - Apps Without A Privacy Policy:
            <br />
          </strong>
          {getTooltipsAppsNoPrivacy()}
          <br /> <br />
          <strong className="bold-text-113">
            - Unique Web Domains: <br />
          </strong>
          {getTooltipsUniqueDomains()}
          <br />‍<br />
          <strong className="bold-text-115">
            - Web Domains (10pm - 6am): <br />
          </strong>
          {getTooltipsWebDomains()}
          <br />‍<br />
          <strong className="bold-text-114">
            - Total Web Domains:&nbsp;
            <br />
          </strong>
          {getTooltipsTotalWebDomains()}
          <br /> <br />
          <strong className="bold-text-114">
            - Apps Blocked:
            <br />
          </strong>
          {getTooltipsAppsBlocked()}
          <br /> <br />
          <strong className="bold-text-114">
            - Web Domains Blocked:
            <br />
          </strong>
          {getTooltipsWebDomainsBlocked()}
          <br /> <br />
          <strong className="bold-text-114">
            - Adult Sites Blocked:
            <br />
          </strong>
          {getTooltipsAdultSitesBlocked()}
        </li>
      </ul>
    );
  };

  const technicalFaqs = () => {
    return (
      <ul className="list-7">
        <li className="list-item-3">
          <strong className="bold-text-65">
            Does Kavalan serve as a DNS provider?
          </strong>
          <br />
          No, Kavalan is not a DNS provider - it acts as a pre-filter to DNS. We
          use other common DNS providers as our downstream DNS service. Kavalan
          pre-filters DNS requests before sending the requests to DNS providers.
          The added advantage is that your DNS query traffic is also anonymized
          by Kavalan.
        </li>
        <li className="list-item-3">
          <strong className="bold-text-65">
            I do not know how to set up or access DNS settings on my home
            internet router. What do I do?
            <br />
          </strong>
          The user manual or documentation for your brand of internet router
          will have the right instructions for specifying DNS settings. However,
          we are happy to work with you to help get you set up as well. Please
          contact us and we can guide you through the steps on the phone.
        </li>
        <li className="list-item-3">
          <strong className="bold-text-65">
            Will I lose my internet connection if Kavalan service goes down or I
            am disconnected from service?
            <br />
          </strong>
          When you register for Kavalan, you will be asked to set the IP address
          of our cloud service as your ‘Primary DNS’ service. You can do one of
          the following additional steps to ensure that your home does NOT lose
          internet connection:
          <br />‍<br />- Your router should allow you to set additional DNS
          services as your ‘Secondary DNS or Back up DNS or DNS2’ service. You
          can take the existing IP address that is set as your ‘Primary DNS’ and
          make that your ‘Secondary DNS or Back up DNS or DNS2&#x27; service.
          Setting a backup DNS is very crucial and please make sure to not skip
          this step.
          <br />‍<br />- You can use either Google (8.8.8.8) or CloudFlare
          (1.1.1.1) as  your ‘Secondary DNS or Back up DNS or DNS2’ service
          <br /> <br />- If your home internet router allows it and if you wish
          to, you could do BOTH of the above steps to ensure no loss of internet
          connectivity on your home network.
        </li>
        <li className="list-item-3">
          <strong className="bold-text-65">
            I do not remember the login/password to my home internet router to
            set it up to work with Kavalan. What can I do?
            <br />
          </strong>
          Unfortunately, Nandi Security will not be able to help you with the
          login/password access to your home internet router. You need to set up
          the router to point to Kavalan to enjoy protection for the whole home.
          Your router vendor may have instructions on how to retrieve the login
          ID and password to your home router. If you use a router provided by
          your ISP, they may have a user name and password stuck on a sticker on
          the router itself
          <br />
          <br />
          But the good news is that even if you do not set up your home internet
          router to use Kavalan, you can still enjoy the benefits of the service
          by setting it up device by device. See the{" "}
          <a href="#Tips-Div">'Tips and Tricks'</a> section for more on that.
          <br /> <br />
        </li>
        <li className="list-item-7">
          <strong className="bold-text-65">
            My internet service provider is already providing me with a DNS
            service. Why do I need Kavalan from Nandi Security?
            <br />
          </strong>
          Kavalan blocks websites on the internet that Internet Service
          Providers will not or cannot block. Internet Service Providers can
          watch and see everything you do on the internet. As a result, they
          collect information about your internet habits and sell those to
          several third parties. Kavalan blocks third party trackers, ad
          networks and ad exchanges preventing them from delivering the ads and
          making money off your habits. The average person is subjected to 1700
          banner ads per month (that is over 6800 for a family of 4) and 1 in
          100 of those are malicious (i.e. may lead to malware sites if you
          clicked on them) – that is an average of 64 malicious attacks per
          month on a family of 4 from internet ads alone.
          <br />
          <br />
          Kavalan will also proactively block any third party companies that
          work with your Internet Service Provider to directly collect
          information about your internet habits. Kavalan will report on your
          dashboard how many such attempts it blocked. <br />
          <br />
          Last but not least, the easiest way to find the answer to this
          question is by signing up for a free trial of the Kavalan. We will
          show you what Kavalan blocks that Internet Service Providers won’t
          block or didn’t block in your home!
        </li>
        <li className="list-item-3">
          <strong className="bold-text-65">
            I want to protect my mobile devices with Kavalan when I travel. Is
            this possible?
            <br />
          </strong>
          No. Kavalan ONLY works to protect devices connected to your home
          network. You need to be logged in on your home wifi network to be
          protected by Kavalan
        </li>
        <li className="list-item-3">
          <strong className="bold-text-65">
            Is Kavalan a VPN service?
            <br />
          </strong>
          No, Kavalan is NOT a VPN service. Most VPN services require an agent
          that needs to be installed on a device by device basis which makes it
          hard to protect connected devices such as televisions, refrigerators,
          smart speakers, etc. That is why Kavalan uses DNS filtering technology
          to protect homes right at the point of their internet connection i.e.
          home routers.
        </li>
        <li className="list-item-8">
          <strong className="bold-text-99">
            How do I check my registered IP address on my Kavalan dashboard?
            <br />
          </strong>
          Once you are logged into your Kavalan dashboard, click on the 'My
          Account' link. The 'My Account' page will show you the IP address that
          you are currently registered with.
        </li>
        <li className="list-item-9">
          <strong className="bold-text-103">
            How do I check the public IP address of my home router?
            <br />
          </strong>
          Your public IP address is what is used by internet sites and apps to
          communicate with your home. You can find this in one of a few ways.
          Please make sure to be logged in on your home wi-fi network. Then you
          can point your web browser to one of the following sites:{" "}
          <strong className="bold-text-102">
            <a
              href="https://ip4.me"
              target="_blank"
              rel="noreferrer"
              className="bold-text-102"
            >
              https://ip4.me
            </a>
          </strong>{" "}
          <strong>OR</strong>{" "}
          <strong className="bold-text-101">
            <a
              href="https://whatismyipaddress.com"
              target="_blank"
              rel="noreferrer"
              className="bold-text-101"
            >
              https://whatismyipaddress.com
            </a>
          </strong>{" "}
          <strong>OR</strong>{" "}
          <strong className="bold-text-100">
            <a
              href="https://whatsmyip.org"
              target="_blank"
              rel="noreferrer"
              className="bold-text-100"
            >
              https://whatsmyip.org
            </a>
          </strong>{" "}
          and note down the IPv4 address that is listed. It is that simple! As
          always, before using any third party websites, please be sure to
          review their privacy policy and terms of service. Nandi Security is
          not affiliated with any of the sites mentioned nor has any business
          relationship with them.{" "}
        </li>
        <li className="list-item-10">
          <strong className="bold-text-105">
            How do I interpret my Data Exposure Report?
            <br />
          </strong>
          Kavalan Data Exposure (a feature of only the Kavalan Standard product)
          Report is intended to provide you with a sense of what kinds of
          information you may have allowed various companies and apps, whose
          services devices in your home accessed, to collect or may have given
          the right to collect in the last 24 hours. It is important to
          understand that Data Exposure Report does not provide a report on what
          exactly was collected about you but rather what the specific company
          or app maker may have disclosed that they MAY collect about you. You
          should always read the privacy policy of the relevant company or app
          to understand the exact scope of data collection and what they do with
          the data. Click on the specific company name to view the privacy
          policy of the specific app, website or service.
        </li>
        <li className="list-item-11">
          <strong className="bold-text-106">
            Why does my Data Exposure Report show me company names I do not
            recognize?
            <br />
          </strong>
          Very often, companies may own multiple websites and/or apps under
          their name or may operate them on behalf of others. The underlying
          privacy policy may hence be part of the parent company or app maker.{" "}
        </li>
      </ul>
    );
  };

  const privacyFaqs = () => {
    return (
      <ul className="list-7">
        <li className="list-item-3">
          <strong className="bold-text-65">
            Does Kavalan share my information with any third parties?
            <br />
          </strong>
          Nandi Security, Inc. does not sell or share our subscriber information
          with any third parties. We use AWS as our downstream DNS provider and
          hence channel your DNS requests through them. However, our service
          masks your IP address from them and makes it appear as if the DNS
          requests are coming from our service. Further, we delete all DNS query
          information every 48 hours! All internet communication has to happen
          with the help of a DNS provider and even if you didn&#x27;t use
          Kavalan, your ISP would be using a DNS provider anyway. Kavalan offers
          the added benefit of anonymizing your DNS queries.
        </li>
        <li className="list-item-3">
          <strong className="bold-text-65">
            What information does Kavalan collect about me and my internet
            habits?
            <br />
          </strong>
          Kavalan collects the IP address of your connected home. This address
          is visible to every website you visit on the internet. This is how we
          identify which homes we need to protect with our service <br />‍<br />
          In addition, we monitor and store the DNS queries sent out by various
          devices in your home. We store the DNS query data to provide you with
          your personalized dashboard and insights and also to monitor if your
          home is attempting to communicate with known malware sites. We delete
          ALL of the DNS query data every 48 hours and the only reason we store
          it for 48 hours is to provide you with a rolling 24 hour dashboard.
          <br />
          <br />
          We cannot see the content of actual websites you visit nor do we get
          any visibility into user names and passwords since we limit our
          monitoring purely to DNS information.
        </li>
        <li className="list-item-3">
          <strong className="bold-text-65">
            What threats do banner ads and internet ads pose to my privacy and
            security?
            <br />
          </strong>
          On average, a person is targeted by 1700 banner ads in a month across
          all online platforms. Banner ads are shown to you when the publisher
          of the website participates in the sharing of information about your
          internet habits. Ad networks and Ad exchanges collect information
          about you from various such sources and build a comprehensive digital
          profile of you that may involve thousands of independent data points.
          The ads you see are oriented towards that but most crucially, this
          digital profile of you and your behaviors is auctioned and monetized
          hundreds of times a day. As a result, any company or entity that seeks
          your information, irrespective of their motives has access to very
          private information such as your geolocation, health needs, sexual
          orientation, driving habits, hobbies, interests and more. Further, one
          in 100 banner ads has been found to be malicious i.e. they lead to
          malware sites that can cause actual damage to devices in your homes or
          even take over your home devices to launch attacks on other entities.
        </li>
      </ul>
    );
  };

  const tipsTricksFaqs = () => {
    return (
      <ul className="list-7">
        <li className="list-item-3">
          <strong className="bold-text-65">
            Can I use Kavalan to protect only specific devices in my home rather
            than the entire home?
            <br />
          </strong>
          Yes, you can! As long as the device in question allows you to
          configure a ‘DNS’ service provider in their Settings, you can set the
          IP address of Kavalan’s service as the ‘Primary DNS’ and avail the
          benefits of being protected. <br />
          <br />
          However, please note that Kavalan only protects the device as long as
          it is on the home network. If you leave the home wi-fi network and
          connect to a different wi-fi/Mobile network, you are NOT protected
          anymore by Kavalan. Please always make sure to set a ‘Secondary DNS or
          Back up DNS or DNS2&#x27; provider so that you continue to have
          internet connectivity when you leave your home.
        </li>
        <li className="list-item-3">
          <strong className="bold-text-65">
            How can I view the activity of just one specific device in my home
            using Kavalan?
            <br />
          </strong>
          Yes, you can do this in a couple of ways:
          <br />‍<br />- If your device allows you to alter DNS settings then
          you can point your device to use Kavalan as its &#x27;Primary
          DNS&#x27; and then remove the Kavalan as the &#x27;Primary DNS&#x27;
          for your home router. This way, only one device is covered by Kavalan
          and your 24 hour dashboard view will show you data just specific to
          that device 24 hours from the time at which you set up the device.
          <br />
          <br />- If you have a second router in your home, you can have the
          device in question connect to that router and configure only that
          router to use Kavalan as the &#x27;Primary DNS&#x27; server. You still
          need to make sure that no other routers or devices in the home are
          pointing to Kavalan as their &#x27;Primary DNS&#x27;. From the point
          you set this up, 24 hours later, your dashboard will reflect data only
          specific to that device.
          <br />
        </li>
      </ul>
    );
  };

  return (
    <div>
      {" "}
      <h1 className="heading-18 faqs-title">Frequently Asked Questions ‍</h1>
      <div className="section-25 wf-section">
        <div className="buton-div-block ">
          <div className="faqs-buttons-container">
            <a
              id="General-Questions-Button"
              href="#General-Questions-Div"
              className="button-26-new w-button faqs-button"
            >
              General Questions
            </a>

            <a
              id="Technical-Questions-Button"
              href="#Technical-Questions"
              className="button-26-new w-button faqs-button"
            >
              Technical Questions
            </a>

            <a
              id="Pricing-Questions-Button"
              href="#Privacy-Questions"
              className="button-26-new w-button faqs-button"
            >
              Privacy Questions
            </a>

            <a
              id="Tips-Button"
              href="#Tips-Div"
              className="button-26-new w-button faqs-button"
            >
              Tips and Tricks
            </a>
          </div>
        </div>
        <a href="#" class="floating-button">
          <i class="fa-solid fa-angles-up"></i>
        </a>
        <div id="General-Questions-Div" className="general-questions-div">
          <h3 className="heading-41">General Questions</h3>
          {generalFaqs()}
        </div>
        <div id="Technical-Questions" className="technical-questions-div">
          <h3 className="heading-42">Technical Questions</h3>
          {technicalFaqs()}
        </div>
        <div id="Privacy-Questions" className="privacy-questions-div-block">
          <h3 className="heading-50">Privacy Questions</h3>
          {privacyFaqs()}
        </div>
        <div id="Tips-Div" className="tips-and-tricks-div">
          <h3 className="heading-51">Tips and Tricks</h3>
          {tipsTricksFaqs()}
        </div>
      </div>
    </div>
  );
}

export default Faq;
