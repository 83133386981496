import { React, useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton,
  Button,
} from "@mui/material";
import {
  getTooltipsTotalWebDomains,
  getTooltipsUniqueDomains,
  getTooltipsWebDomains,
} from "../common/helperFunctions";

function InternetActivityInsights(props) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="custom-card">
      <div className="custom-card-title middle">Internet Activity Insights</div>

      <div className="actions">
        <div className="cells-div-middle-card">
          <div className="button-div-title">
            Unique Web <br /> Domains{" "}
          </div>
          <div className="numbers-data">{props.uniqueDomainsInvoked}</div>
        </div>

        <div className="vertical-divider"></div>

        <div className="cells-div-middle-card">
          <div className="button-div-title">
            Web Domains <br /> (10pm - 6am)
          </div>
          <div className="numbers-data">
            {props.domainsInvokedDuringBedTime}
          </div>
        </div>
      </div>

      <Divider variant="middle" className="horizontal-divider"></Divider>

      <div className="actions">
        <div className="cells-div-middle-card">
          <div className="button-div-title">Total Web Domains</div>
          <div className="numbers-data">{props.domainsInvoked}</div>
        </div>

        <div className="vertical-divider"></div>

        <div className="cells-div-middle-card"></div>
      </div>

      <div className="expand-icon">
        <IconButton onClick={handleOpen}>
          <i
            class="fa-regular fa-circle-question"
            style={{ color: "#262626" }}
          ></i>
        </IconButton>
      </div>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent>
          <DialogContentText>
            <ul className="list-7">
              <li className="list-internet-activity">
                <strong className="bold-text-65">Unique Web Domains</strong>
                <br />
                {getTooltipsUniqueDomains()}
              </li>
              <br />
              <li className="list-internet-activity">
                <strong className="bold-text-65">
                  Web Domains (10pm - 6am)
                </strong>
                <br />
                {getTooltipsWebDomains()}
              </li>
              <br />
              <li className="list-internet-activity">
                <strong className="bold-text-65">Total Web Domains</strong>
                <br />
                {getTooltipsTotalWebDomains()}
              </li>
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default InternetActivityInsights;
