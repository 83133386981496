import React, { useEffect, useState } from "react";
import { blockApps, getAppsBlockStatus } from "../api";
import { useNavigate, Link } from "react-router-dom";
import { PiSpinner } from "react-icons/pi";
import { setDashboardData, getDashboardData } from "../common/StorageUtils";

import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const List = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#222B34" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function BlockApps(props) {
  const [error, setError] = useState(false); //eslint-disable-line
  const [appStatus, setAppStatus] = useState({});
  const initialAppsStatus = {};
  const [appsType, setAppsType] = useState("social-media");
  const [showMessage, setShowMessage] = useState(false);
  const [currentApp, setCurrentApp] = useState(undefined);
  const [spinner, setSpinner] = useState(true);
  const [activeAppClass, setActiveAppClass] = useState("social-media");
  const [dataLoaded, setDataLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    let mounted = true;

    if (!dataLoaded) {
      setSpinner(true);
      if (props.product !== "kavalan") {
        navigate("/Dashboard");
        setSpinner(false);
      } else {
        getAppsBlockStatus()
          .then((response) => {
            console.log("response", response);
            if (mounted) {
              Object.entries(response).map(
                ([key, val]) =>
                  (initialAppsStatus[key] = val ? "blocked" : "unblocked"),
              );
              setAppStatus(initialAppsStatus);
              setSpinner(false);
              setDataLoaded(true);
            }
          })
          .catch((error) => {
            setError(true);
            setSpinner(false);
          });
      }
    }
    return () => {
      mounted = false;
    };
  }, []);

  const appData = {
    "social-media": [
      { name: "facebook", img: "facebook-button" },
      { name: "whatsapp", img: "whatsapp-button" },
      { name: "instagram", img: "instagram-button" },
      { name: "twitter", img: "twitter-button" },
      { name: "tiktok", img: "tiktok-button" },
      { name: "linkedin", img: "linkedin-button" },
      { name: "yelp", img: "yelp-button" },
    ],
    streaming: [
      { name: "youtube", img: "youtube-button" },
      { name: "twitch", img: "twitch-button" },
      { name: "vudu", img: "vudu-button" },
    ],
    gaming: [
      { name: "roblox", img: "roblox-button" },
      { name: "fortnite", img: "fortnite-button" },
    ],
  };

  const appClicked = async (appName) => {
    const newStatusRequest = appStatus[appName] === "unblocked" ? true : false;
    const newStatusTransit =
      appStatus[appName] === "unblocked" ? "blocking" : "unblocking";
    setAppStatus((prev) => {
      return { ...prev, [appName]: newStatusTransit };
    });
    const res = await blockApps(appName, newStatusRequest);
    setShowMessage(res.appStatus);
    setCurrentApp(capitalizeFirstLetter(appName));
    setAppStatus((prev) => {
      return { ...prev, [appName]: res.appStatus ? "blocked" : "unblocked" };
    });

    //retrieve the current value of the SessionStorage:
    const sessionStorageValue = getDashboardData();
    if (sessionStorageValue !== null) {
      //update the current number of the blocked apps in the SessionStorage value:
      sessionStorageValue.custom.noOfAppsBlocked += res.appStatus ? 1 : -1;

      //call a function that will put an updated value to the Session Storage:
      setDashboardData(sessionStorageValue);
    }
  };

  const handleAppTypeSwitch = (appType) => {
    setActiveAppClass(appType);
    setAppsType(appType);
  };

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  const Blocking = () => {
    return (
      <div className="waveTextAnimated">
        <span>Blocking</span>
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </div>
    );
  };
  const Unblocking = () => {
    return (
      <div className="waveTextAnimated">
        <span>Unblocking</span>
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </div>
    );
  };

  const statuses = {
    blocked: "Blocked",
    unblocked: "Unblocked",
    blocking: <Blocking />,
    unblocking: <Unblocking />,
  };

  return (
    <div>
      <div className="app-buttons-section wf-section">
        <Item className="container-new w-container">
          <h3 className="heading-28">Block/Unblock Apps</h3>
          <div className="text-block-76">
            Click button to block/unblock that app for all devices in your home
          </div>
          {spinner && (
            <div className="pageload-spinner">
              <PiSpinner
                style={{
                  fontSize: "3rem",
                  animation: "spin 2s linear infinite",
                }}
              />
            </div>
          )}
          {/* Parent section div */}
          <List
            data-current="Streaming Apps"
            data-easing="ease"
            data-duration-in={300}
            data-duration-out={100}
            className="tabs-7 w-tabs blockAppsContent"
          >
            {/* Parent button divs for all apps */}
            <div className="w-tab-menu classButtons" role="tablist">
              <button
                onClick={() => handleAppTypeSwitch("social-media")}
                className={`tab-link-social-media w-inline-block w-tab-link ${
                  activeAppClass === "social-media" ? "w--current" : ""
                }`}
              >
                social media
              </button>
              <button
                onClick={() => handleAppTypeSwitch("streaming")}
                className={`tab-link-streaming-apps w-inline-block w-tab-link ${
                  activeAppClass === "streaming" ? "w--current" : ""
                }`}
              >
                streaming
              </button>
              <button
                onClick={() => handleAppTypeSwitch("gaming")}
                className={`tab-link-gaming w-inline-block w-tab-link ${
                  activeAppClass === "gaming" ? "w--current" : ""
                }`}
              >
                gaming
              </button>
            </div>
            {/* Parent div for all apps */}
            <div className="appsContainer">
              {appData[appsType].map((app, i) => {
                return (
                  <div
                    className="appDetails"
                    key={`app-button-${app.name}-${i}`}
                  >
                    <div
                      className={`${app.name}-button w-button`}
                      id={`${app.name}`}
                      onClick={() => appClicked(app.name)}
                    />
                    <div>
                      <div className="appName">
                        {capitalizeFirstLetter(app.name)}
                      </div>
                      <div
                        className="appStatus"
                        style={{ backgroundColor: "transparent" }}
                      >
                        {statuses[appStatus[app.name]]}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="backButton">
              <Link to="/dashboard" className="button-26-new w-button">
                Back to Dashboard
              </Link>
            </div>
            <div></div>
          </List>
          {showMessage && (
            <div className="w-form-submitted">
              "{currentApp} has been successfully blocked. {currentApp} may
              still be cached by devices, apps, and browsers. To clear the
              caches and ensure that the blocking is effective, please restart
              any relevant apps, devices, or browsers."
            </div>
          )}
        </Item>
      </div>
    </div>
  );
}

export default BlockApps;
