import { React, useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton,
  Button,
} from "@mui/material";

import {
  getTooltipsAppsBlocked,
  getTooltipsWebDomainsBlocked,
} from "../common/helperFunctions";

function BlockedInsights(props) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="custom-card">
      <div className="custom-card-title right">You've Blocked</div>
      {/* <div className="blocked-card" style={{ marginTop: "25px" }}> */}
      <div className="actions">
        <div className="cells-div-right-card">
          <div className="button-div-title">Blocked Apps</div>
          <div className="numbers-data">{props.blockedApps}</div>
        </div>
      </div>
      <Divider variant="middle" className="horizontal-divider"></Divider>
      <div className="actions">
        <div className="cells-div-right-card">
          <div className="button-div-title">Blocked Websites</div>
          <div className="numbers-data">{props.blockedDomains}</div>
        </div>
      </div>
      <div className="expand-icon">
        <IconButton onClick={handleOpen}>
          <i
            class="fa-regular fa-circle-question"
            style={{ color: "#262626" }}
          ></i>
        </IconButton>
      </div>
      {/* </div> */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent>
          <DialogContentText>
            <ul className="list-7">
              <li className="list-blocked">
                <strong className="bold-text-65">Blocked Apps</strong>
                <br />
                {getTooltipsAppsBlocked()}
              </li>
              <br />
              <li className="list-blocked">
                <strong className="bold-text-65">Blocked Websites</strong>
                <br />
                {getTooltipsWebDomainsBlocked()}
              </li>
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default BlockedInsights;
