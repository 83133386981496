//eslint-disable-next-line
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import { webDomainReport } from "../api";
import { PiSpinner } from "react-icons/pi";

function hasElements(arr) {
  return !arr || arr.length === 0;
}

function PrivacyRiskReport(props) {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [activeTab, setActiveTab] = useState("Session Recording");
  const [spinner, setSpinner] = useState(true);
  const [blackLightData, setBlackLightData] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);

    if (props.product !== "kavalan") {
      navigate("/Dashboard");
    }
    setSpinner(true);
    webDomainReport()
      .then((response) => {
        setBlackLightData(response);
        handleTabClick("Session Recording");
        setSpinner(false);
      })
      .catch((error) => {
        setError("Oops! We hit a snag. We are aware of it and will fix ASAP.");
        console.log(error);
        setSpinner(false);
      });
  }, []);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    setError(false);

    if (tabName === "Unencrypted Sites") {
      setSelectedOption("Unencrypted Sites");
    }
    if (tabName === "Keystroke Recording") {
      setSelectedOption("Keystroke Recording");
    }
    if (tabName === "Session Recording") {
      setSelectedOption("Session Recording");
    }
  };

  const NoPrivacyPolicy = () => {
    const domainList = !hasElements(blackLightData.dpa.noPrivacyPolicy) ? (
      blackLightData.dpa.noPrivacyPolicy.map((domain) => (
        <div key={domain}>{domain}</div>
      ))
    ) : (
      <div className="div-block-53">
        No apps without privacy policy detected
      </div>
    );

    return domainList;
  };

  const SessionRecording = () => {
    const domainList = !hasElements(
      blackLightData.blacklight.session_tracking,
    ) ? (
      blackLightData.blacklight.session_tracking.map((domain) => (
        <div key={domain}>{domain}</div>
      ))
    ) : (
      <div className="div-block-53">No screen recording sites detected</div>
    );

    return domainList;
  };

  const KeystrokeRecording = () => {
    const domainList = !hasElements(blackLightData.blacklight.key_logging) ? (
      blackLightData.blacklight.key_logging.map((domain) => (
        <div key={domain}>{domain}</div>
      ))
    ) : (
      <div className="div-block-53">No keystroke logging sites detected</div>
    );

    return domainList;
  };

  return (
    <div className="app-buttons-section">
      <div className="container-new w-container">
        <h1 className="heading-28">Web Privacy Risk Report</h1>
        <div className="text-block-213">
          Click on a privacy risk type to see the list of web sites accessed in
          the last 24 hours
        </div>
        {spinner && (
          <div className="pageload-spinner">
            <PiSpinner
              style={{ fontSize: "3rem", animation: "spin 2s linear infinite" }}
            />
          </div>
        )}
        {/* Page loading spinner is displayed here */}
        <div
          data-current={activeTab}
          data-easing="ease"
          data-duration-in={300}
          data-duration-out={100}
          className="privacy-risk-report-tab w-tabs"
        >
          <div className="tabs-menu-8 w-tab-menu">
            <button
              data-w-tab="Session Recording"
              className={`tab-link-session-recording w-inline-block w-tab-link ${
                activeTab === "Session Recording" ? "w--current" : ""
              }`}
              onClick={() => handleTabClick("Session Recording")}
            >
              <div className="text-block-209">screen recording</div>
            </button>
            <button
              data-w-tab="Keystroke Recording"
              className={`tab-link-keystroke-recording w-inline-block w-tab-link ${
                activeTab === "Keystroke Recording" ? "w--current" : ""
              }`}
              onClick={() => handleTabClick("Keystroke Recording")}
            >
              <div className="text-block-210">keystroke recording</div>
            </button>
            <button
              data-w-tab="Unencrypted Sites"
              className={`tab-link-unencrypted-sites w-inline-block w-tab-link ${
                activeTab === "Unencrypted Sites" ? "w--current" : ""
              }`}
              onClick={() => handleTabClick("Unencrypted Sites")}
            >
              <div className="text-block-211">no privacy policy</div>
            </button>
          </div>
          <div className="tabs-content-7 w-tab-content">
            {selectedOption === "Session Recording" && (
              <div
                data-w-tab="Session Recording"
                className={` w-tab-pane ${
                  activeTab === "Session Recording" ? "w--tab-active" : ""
                }`}
              >
                <div className="text-block-215">
                  {error && <div className="session-timeout">{error}</div>}
                  {!error && <SessionRecording />}
                </div>
              </div>
            )}
            {selectedOption === "Keystroke Recording" && (
              <div
                data-w-tab="Keystroke Recording"
                className={`w-tab-pane ${
                  activeTab === "Keystroke Recording" ? "w--tab-active" : ""
                }`}
              >
                <div className="text-block-215">
                  {error && <div className="keystroke-timeout">{error}</div>}
                  {!error && <KeystrokeRecording />}
                </div>
              </div>
            )}
            {selectedOption === "Unencrypted Sites" && (
              <div
                data-w-tab="Unencrypted Sites"
                className={`w-tab-pane ${
                  activeTab === "Unencrypted Sites" ? "w--tab-active" : ""
                }`}
              >
                <div className="text-block-215">
                  {error && (
                    <div className="privacy-policy-timeout">{error}</div>
                  )}
                  {!error && <NoPrivacyPolicy />}
                </div>
              </div>
            )}
          </div>
        </div>
        <Link to="/dashboard" className="button-26 w-button">
          Back to dashboard
        </Link>
      </div>
      <div className="text-block-216">
        Disclaimer: This report is an estimation of risk based on prior scans.
        Websites may have changed since the last scan. Please always review the
        relevant website's privacy policy or contact the website owner for the
        latest information. Screen recording and key stroke logging may not
        indicate malicious behavior and these technologies may be used by the
        website owner purely for website performance reasons.
      </div>
    </div>
  );
}

export default PrivacyRiskReport;
