import { API_BASE_URL, ACCESS_TOKEN } from "../constants";

const request = (options) => {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  const headers = new Headers({
    "Content-Type": "application/json",
  });

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem(ACCESS_TOKEN),
    );
  }

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);

  return fetch(options.url, options)
    .then((response) => {
      if (!response.ok) {
        return Promise.reject(new Error(response.statusText));
      }
      return response;
    })
    .then((response) => {
      const contentType = response.headers.get("content-type");

      if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.json();
      } else {
        return response.text();
      }
    })
    .catch((err) => {
      console.log(err);
      return Promise.reject(new Error(err));
    });
};

export function getCurrentUser() {
  return request({
    url: API_BASE_URL + "/api/v1/user/me",
    method: "GET",
  });
}

export function saveAgreement(timeZone) {
  const payload = {
    timeZone: timeZone,
  };

  return request({
    url: API_BASE_URL + "/api/v1/register/agreement",
    method: "POST",
    body: JSON.stringify(payload),
  });
}

export function wifiAgreement(timeZone) {
  const payload = {
    timeZone: timeZone,
  };

  return request({
    url: API_BASE_URL + "/api/v1/register/wifi-agreement",
    method: "POST",
    body: JSON.stringify(payload),
  });
}

export function isIspUser() {
  return request({
    url: API_BASE_URL + "/api/v1/register/isIspUser",
    method: "GET",
  });
}

export function savePaymentPlan(product, paymentPlan, isIspUser) {
  const payload = {
    product: product,
    paymentPlan: paymentPlan,
    isIspUser: isIspUser,
  };

  return request({
    url: API_BASE_URL + "/api/v1/payment/plan",
    method: "POST",
    body: JSON.stringify(payload),
  });
}

export function updatePaymentPlan(product, paymentPlan, isIspUser) {
  const payload = {
    product: product,
    paymentPlan: paymentPlan,
    isIspUser: isIspUser,
  };

  return request({
    url: API_BASE_URL + "/api/v1/payment/update-plan",
    method: "POST",
    body: JSON.stringify(payload),
  });
}

export function blackLight(url) {
  console.log(url);
  return request({
    url: API_BASE_URL + "/api/blacklight/" + url,
    method: "GET",
  });
}

export function webDomainReport() {
  return request({
    url: API_BASE_URL + "/api/blacklight/webdomain-details",
    method: "GET",
  });
}

export function saveSubscription(paymentMethodId, name, promoCode) {
  const payload = {
    paymentMethodId: paymentMethodId,
    name: name,
    promoCode: promoCode,
  };

  return request({
    url: API_BASE_URL + "/api/v1/payment/subscribe",
    method: "POST",
    body: JSON.stringify(payload),
  });
}

export function validatePromoCode(code) {
  const payload = {
    promoCode: code,
  };

  return request({
    url: API_BASE_URL + "/api/v1/payment/promo",
    method: "POST",
    body: JSON.stringify(payload),
  });
}

export function getDashboard() {
  return request({
    url: API_BASE_URL + "/api/v1/user/dashboard-details",
    method: "GET",
  });
}

export function getDomainsBlocked() {
  return request({
    url: API_BASE_URL + "/api/v1/user/domains-blocked",
    method: "GET",
  });
}

export function updateIp() {
  return request({
    url: API_BASE_URL + "/api/v1/register/updateIp",
    method: "POST",
  });
}
export function blockApps(appName, appStatus) {
  const payload = {
    appName: appName,
    appStatus: appStatus,
  };

  return request({
    url: API_BASE_URL + "/api/v1/user/block-apps",
    method: "POST",
    body: JSON.stringify(payload),
  });
}

export function getAppsBlockStatus() {
  return request({
    url: API_BASE_URL + "/api/v1/user/apps-status",
    method: "GET",
  });
}

export function blockWebsites(website, status) {
  const payload = {
    websiteName: website,
    websiteStatus: status,
  };

  return request({
    url: API_BASE_URL + "/api/v1/user/block-website",
    method: "POST",
    body: JSON.stringify(payload),
  });
}

export function getWebsites() {
  return request({
    url: API_BASE_URL + "/api/v1/user/getWebsites",
    method: "GET",
  });
}

export function getBlockedDomainsCount() {
  return request({
    url: API_BASE_URL + "/api/v1/user/getBlockedDomainsCount",
    method: "GET",
  });
}

export function getDpaData() {
  return request({
    url: API_BASE_URL + "/api/v1/user/dpa-data",
    method: "GET",
  });
}

export function deactivateUser() {
  return request({
    url: API_BASE_URL + "/api/v1/user/deactivate-user",
    method: "GET",
  });
}
