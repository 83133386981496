import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Header({
  authenticated,
  currentUser,
  onLogout,
  isDashboard,
  isProfile,
  // mode,
  // setMode,
}) {
  const [mobileMenu, setMobileMenu] = useState(false);

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (
        mobileMenu &&
        !document.getElementById("menuId").contains(event.target)
      ) {
        setMobileMenu(false);
      }
    };
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [mobileMenu]);
  /**
   * In line :37,
   * it will check the states of the const and update the header
   * as per required
   */

  //Unused Function delete after 12/31/2024
  // const handleChange = () => {
  //   if (mode) {
  //     setMode(false);
  //   } else {
  //     setMode(true);
  //   }
  // };

  return (
    <div>
      <div className="logo-and-menu-section wf-section">
        <div className="columns w-row">
          <div className="column w-col w-col-6">
            <Link to="/dashboard">
              <img
                src="images/kavalan-logo_color.png"
                loading="lazy"
                width="300"
                sizes="(max-width: 767px) 43vw, 300px"
                alt=""
              />
            </Link>
          </div>
          <div className="column-4 w-col w-col-6">
            <div
              data-animation="default"
              data-collapse="medium"
              data-duration="400"
              data-easing="ease"
              data-easing2="ease"
              role="banner"
              className="navbar w-nav"
            >
              <div className="w-container">
                <nav role="navigation" className="w-nav-menu">
                  {authenticated && isProfile ? (
                    <Link to="/dashboard" className="w-nav-link">
                      Dashboard
                    </Link>
                  ) : isDashboard ? null : (
                    <Link to="/dashboard" className="w-nav-link">
                      Home
                    </Link>
                  )}
                  {authenticated &&
                    currentUser.status === "active" &&
                    isDashboard && (
                      <Link to="/setupinstruction" className="w-nav-link">
                        {" "}
                        Setup Instructions
                      </Link>
                    )}
                  <Link to="/faq" className="w-nav-link">
                    FAQs
                  </Link>
                  <a
                    href="mailto:support@nandisecurity.com?subject=Kavalan%20Support%20Request"
                    className="w-nav-link"
                  >
                    Contact
                  </a>
                  {authenticated &&
                    currentUser.status === "active" &&
                    isDashboard && (
                      <Link to="profile" className="w-nav-link">
                        My Account
                      </Link>
                    )}
                  {authenticated ? (
                    <a href=" " onClick={onLogout} className="w-nav-link">
                      Logout
                    </a>
                  ) : (
                    <a href="login" className="w-nav-link">
                      Login
                    </a>
                  )}
                </nav>
                <div
                  className="w-nav-button"
                  onClick={() => setMobileMenu(!mobileMenu)}
                  id="menuId"
                >
                  <div className="w-icon-nav-menu"></div>
                </div>
              </div>
              <div
                className={`w-nav-overlay ${mobileMenu ? "show" : ""}`}
                data-wf-ignore=""
                id="w-nav-overlay-0"
                style={{ height: "400px" }}
              >
                <nav
                  role="navigation"
                  className="w-nav-menu"
                  style={{
                    transform: "translateY(0px) translateX(0px)",
                    transition: "transform 400ms ease 0s",
                  }}
                  data-nav-menu-open=""
                >
                  {isDashboard ? null : (
                    <Link
                      to="dashboard"
                      className="w-nav-link w--nav-link-open"
                      style={{ maxWidth: "728px" }}
                    >
                      Home
                    </Link>
                  )}
                  <Link
                    to="setupinstruction"
                    className="w-nav-link w--nav-link-open"
                    style={{ maxWidth: "728px" }}
                  >
                    Setup Instructions
                  </Link>
                  <Link
                    to="faq"
                    className="w-nav-link w--nav-link-open"
                    style={{ maxWidth: "728px" }}
                  >
                    FAQs
                  </Link>
                  <a
                    href="mailto:support@nandisecurity.com?subject=Kavalan%20Support%20Request"
                    className="w-nav-link w--nav-link-open"
                    style={{ maxWidth: "728px" }}
                  >
                    Contact
                  </a>
                  {isProfile
                    ? null
                    : authenticated &&
                      currentUser &&
                      currentUser.registered && (
                        <Link
                          to="profile"
                          className="w-nav-link w--nav-link-open"
                        >
                          My Account
                        </Link>
                      )}

                  {authenticated ? (
                    <a
                      href=" "
                      onClick={onLogout}
                      className="w-nav-link w--nav-link-open"
                    >
                      Logout
                    </a>
                  ) : (
                    <a href="login" className="w-nav-link w--nav-link-open">
                      Login
                    </a>
                  )}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
