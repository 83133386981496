import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton,
  Button,
} from "@mui/material";
import { React, useState } from "react";
import {
  getTooltipsAccessAttempts,
  getTooltipsAppsNoPrivacy,
  getTooltipsKeyStroke,
  getTooltipsMalicious,
  getTooltipsScreenRecording,
  getTooltipsUniqueThreats,
} from "../common/helperFunctions";

function PrivacyInsights(props) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="custom-card">
      <div className="custom-card-title left">
        Privacy and Security Insights
      </div>

      <div className="actions">
        <div className="cells-div-left">
          <div className="button-div-title">
            Unique Threats <br /> Blocked{" "}
          </div>
          <div className="numbers-data">{props.uniqueThreats}</div>
        </div>

        <div className="vertical-divider"></div>

        <div className="cells-div-left">
          <div className="button-div-title">
            Access Attempts <br />
            Blocked
          </div>
          <div className="numbers-data">{props.accessAttemptsBlocked}</div>
        </div>

        <div className="vertical-divider"></div>

        <div className="cells-div-left">
          <div className="button-div-title">
            Malicious Domains <br />
            Blocked
          </div>
          <div className="numbers-data">{props.maliciousDomainsBlocked}</div>
        </div>
      </div>

      <Divider variant="middle" className="horizontal-divider"></Divider>

      <div>
        <div className="actions">
          <div className="cells-div-left">
            <div className="button-div-title">
              Screen Recording Sites Detected{" "}
            </div>
            <div className="numbers-data">{props.screenRecordingSites}</div>
          </div>

          <div className="vertical-divider"></div>

          <div className="cells-div-left">
            <div className="button-div-title">
              Key Stroke Logging Sites Detected
            </div>
            <div className="numbers-data">{props.keyLoggingSites}</div>
          </div>

          <div className="vertical-divider"></div>

          <div className="cells-div-left">
            <div className="button-div-title">
              Apps Without A Privacy Policy
            </div>
            <div className="numbers-data">{props.noPrivacyPolicyApps}</div>
          </div>
        </div>
      </div>
      <div className="expand-icon">
        <IconButton onClick={handleOpen}>
          <i
            class="fa-regular fa-circle-question"
            style={{ color: "#262626" }}
          ></i>
        </IconButton>
      </div>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent>
          <DialogContentText>
            <ul className="list-7">
              <li className="list-privacy-insights">
                <strong className="bold-text-65">Unique Threats Blocked</strong>
                <br />
                {getTooltipsUniqueThreats()}
              </li>
              <br />
              <li className="list-privacy-insights">
                <strong className="bold-text-65">
                  Access Attempts Blocked
                </strong>
                <br />
                {getTooltipsAccessAttempts()}
              </li>
              <br />
              <li className="list-privacy-insights">
                <strong className="bold-text-65">
                  Malicious Domains Blocked
                </strong>
                <br />
                {getTooltipsMalicious()}
              </li>
              <br />
              <li className="list-privacy-insights">
                <strong className="bold-text-65">
                  Screen Recording Sites Detected
                </strong>
                <br />
                {getTooltipsScreenRecording()}
              </li>
              <br />
              <li className="list-privacy-insights">
                <strong className="bold-text-65">
                  Key Stroke Logging Sites Detected
                </strong>
                <br />
                {getTooltipsKeyStroke()}
              </li>
              <br />
              <li className="list-privacy-insights">
                <strong className="bold-text-65">
                  Apps Without A Privacy Policy
                </strong>
                <br />
                {getTooltipsAppsNoPrivacy()}
              </li>
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PrivacyInsights;
