import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { wifiAgreement } from "../api";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
// import { savePaymentPlan } from "../api"; //unused import

function HomeWifiConfirmation() {
  const [loggedinConfirmation, setLoggedinConfirmation] = useState(false);
  const [ableToLoginConfirmation, setAbleToLoginConfirmation] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const saveUserWifiConfirmation = () => {
    setInProgress(true);
    wifiAgreement(moment.tz.guess())
      .then((res) => {
        navigate("/product");
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setInProgress(false);
      });
  };
  return (
    <div>
      <div className="subscription-section wf-section">
        <h1 className="heading-30">
          Please <strong className="bold-text-96">STOP</strong> and Read
          Carefully
        </h1>
        <div className="div-block-36">
          <div className="div-38">
            <div className="form-block-4 w-form">
              <div className="form-3">
                <div className="text-block-176">
                  Please confirm you have access to your home Wi-Fi
                </div>
                <img
                  src={require("../images/total-web-domains-accessed_RD.png")}
                  loading="lazy"
                  //width='636.5'
                  //srcSet='images/total-web-domains-accessed_RD-p-500.png 500w, images/total-web-domains-accessed_RD-p-800.png 800w, images/total-web-domains-accessed_RD-p-1080.png 1080w, images/total-web-domains-accessed_RD.png 1273w'
                  width="120"
                  height="120"
                  alt=""
                  className="image-39"
                />
                <label className="w-checkbox checkbox-field">
                  <input
                    type="checkbox"
                    name="TOS-and-Privacy-Policy"
                    id="TOS-and-Privacy-Policy"
                    data-name="TOS and Privacy Policy"
                    className="w-checkbox-input checkbox-2"
                    onChange={() =>
                      setLoggedinConfirmation(!loggedinConfirmation)
                    }
                  />
                  <span
                    className="checkbox-label-2 w-form-label"
                    htmlFor="TOS-and-Privacy-Policy"
                  >
                    I confirm I am logged in on my home Wi-Fi network
                  </span>
                </label>
                <label className="w-checkbox checkbox-field-3">
                  <input
                    type="checkbox"
                    id="checkbox"
                    name="checkbox"
                    data-name="Checkbox"
                    required=""
                    className="w-checkbox-input"
                    onChange={() =>
                      setAbleToLoginConfirmation(!ableToLoginConfirmation)
                    }
                  />
                  <span
                    className="checkbox-label-4 w-form-label"
                    htmlFor="checkbox"
                  >
                    I confirm I am able to login to my home Wi-Fi router
                  </span>
                </label>
                <button
                  className={`button-10 w-button ${
                    !(loggedinConfirmation && ableToLoginConfirmation) ||
                    inProgress
                      ? "isDisabled"
                      : ""
                  }`}
                  disabled={
                    !(loggedinConfirmation && ableToLoginConfirmation) ||
                    inProgress
                  }
                  onClick={saveUserWifiConfirmation}
                >
                  Next
                </button>
                {inProgress && (
                  <Box>
                    <CircularProgress />
                  </Box>
                )}
              </div>
              <div className="w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div>
              {error && (
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              )}
            </div>
          </div>
          <h3 className="heading-67">Why do you need to do this?</h3>
          <div className="text-block-177">
            In order to use Kavalan, you will need to change a setting on your
            home router. It is THAT simple. Change one setting and protect every
            device in your home!
          </div>
          <h4 className="heading-31">
            <strong className="bold-text-82">Next Step:</strong> Select your
            Subscription Plan - Light or Standard or Free Trial
          </h4>
        </div>
      </div>
    </div>
  );
}
export default HomeWifiConfirmation;
