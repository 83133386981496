import { useEffect, useState } from "react";
import popupStyles from "../css/custom-popup.module.css";
import PropTypes from "prop-types";
const ProgressPopup = (props) => {
  const [show, setShow] = useState(false);

  // Unused function delete after 12/31/2024
  // const closeHandler = (e) => {
  //   setShow(false);
  //   props.onClose(false);
  // };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  return (
    <div
      style={{
        visibility: show ? "visible" : "hidden",
        opacity: show ? "1" : "0",
      }}
      className={popupStyles.overlay}
    >
      <div className={popupStyles.popup}>
        {/* <span className={popupStyles.close} onClick={closeHandler}>
          &times;
        </span> */}
        <div className={popupStyles.content}>{props.children}</div>
      </div>
    </div>
  );
};

ProgressPopup.propTypes = {
  show: PropTypes.bool.isRequired,
};
export default ProgressPopup;
