import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Login from "./components/Login";
import Product from "./components/Product";
import PaymentConfirmation from "./components/PaymentConfirmation";
// import Dashboard from "./components/Dashboard";
import Dashboard2 from "./components/Dashboard2";
import Home from "./components/Home";
import Confirmation from "./components/Confirmation";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { getCurrentUser } from "./api";
import { ACCESS_TOKEN } from "./constants";
import PrivateRoute from "./common/PrivateRoute";
import OAuth2RedirectHandler from "./common/OAuth2RedirectHandler";
import Profile from "./components/Profile";
import Agreement from "./components/Agreement";
import Setup from "./components/Setup";
import "./App.css";
import AuthVerify from "./common/AuthVerify";
import Checkout from "./components/Checkout";
import Faq from "./components/Faq";

import BlockApps from "./components/BlockApps";
import BlockWebsites from "./components/BlockWebsites";
import HomeWifiConfirmation from "./components/HomeWifiConfirmation";
import DeepPrivacyAnalysis from "./components/DeepPrivacyAnalysis";
import SetupInstruction from "./components/SetupInstruction";

import PrivacyScan from "./components/PrivacyScan";
import PrivacyRiskReport from "./components/PrivacyRiskReport";
// import useMediaQuery from "@mui/material/useMediaQuery";

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [product, setproduct] = useState("");
  const location = useLocation();
  const currentPath = location.pathname;
  const [isDashboard, setIsDashboard] = useState(false);
  const [isProfile, setIsProfile] = useState(false);

  useEffect(() => {
    loadCurrentlyLoggedInUser();

    /*for changing the fields in the header we locate the path and update the state as per 
      path*/

    setIsDashboard(currentPath === "/dashboard" || currentPath === "/");

    setIsProfile(
      currentPath === "/profile" ||
        currentPath === "/setupinstruction" ||
        currentPath === "/blockapps" ||
        currentPath === "/blockwebsites" ||
        currentPath === "/deep-privacy-analysis",
    );
  }, [location.pathname, currentPath]);

  const loadCurrentlyLoggedInUser = () => {
    getCurrentUser()
      .then((response) => {
        if (response.userId) {
          setproduct(response.product);
          setCurrentUser(response);
          setAuthenticated(true);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleLogout = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    setAuthenticated(false);
    setCurrentUser(null);
    console.log("You're safely logged out!");
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="App">
      <div className="app-header">
        <Header
          authenticated={authenticated}
          currentUser={currentUser}
          onLogout={handleLogout}
          isDashboard={isDashboard}
          isProfile={isProfile}
        />
        <AuthVerify onLogout={handleLogout} />
      </div>
      <div className="app-body" style={{ marginTop: "12em" }}>
        <Routes>
          <Route path="/" element={<Home currentUser={currentUser} />} />
          <Route
            path="/login"
            element={<Login authenticated={authenticated} />}
          ></Route>
          <Route
            path="/signup"
            element={<Login authenticated={authenticated} />}
          ></Route>
          <Route path="/faq" element={<Faq />}></Route>

          <Route
            path="/oauth2/redirect"
            element={<OAuth2RedirectHandler />}
          ></Route>
          <Route
            path="/profile"
            element={
              <PrivateRoute authenticated={authenticated}>
                <Profile currentUser={currentUser} />
              </PrivateRoute>
            }
          />
          <Route
            path="/agreement"
            element={
              <PrivateRoute authenticated={authenticated}>
                <Agreement currentUser={currentUser} />
              </PrivateRoute>
            }
          />
          <Route
            path="/product"
            element={
              <PrivateRoute authenticated={authenticated}>
                <Product currentUser={currentUser} />
              </PrivateRoute>
            }
          />
          <Route
            path="/checkout"
            element={
              <PrivateRoute authenticated={authenticated}>
                <Checkout currentUser={currentUser} />
              </PrivateRoute>
            }
          />
          <Route
            path="/payment-confirmation"
            element={
              <PrivateRoute authenticated={authenticated}>
                <PaymentConfirmation />
              </PrivateRoute>
            }
          />
          <Route
            path="/setup"
            element={
              <PrivateRoute authenticated={authenticated}>
                <Setup currentUser={currentUser} />
              </PrivateRoute>
            }
          />
          <Route
            path="/confirmation"
            element={
              <PrivateRoute authenticated={authenticated}>
                <Confirmation currentUser={currentUser} />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute authenticated={authenticated}>
                {/* <Dashboard product={product} currentUser={currentUser} /> */}
                <Dashboard2 product={product} currentUser={currentUser} />
              </PrivateRoute>
            }
          />
          <Route
            path="/home-wifi-confirmation"
            element={
              <PrivateRoute authenticated={authenticated}>
                <HomeWifiConfirmation />
              </PrivateRoute>
            }
          />
          <Route
            path="/blockapps"
            element={
              <PrivateRoute authenticated={authenticated}>
                <BlockApps product={product} />
              </PrivateRoute>
            }
          />
          <Route
            path="/blockwebsites"
            element={
              <PrivateRoute authenticated={authenticated}>
                <BlockWebsites product={product} />
              </PrivateRoute>
            }
          />
          <Route
            path="/deep-privacy-analysis"
            element={
              <PrivateRoute authenticated={authenticated}>
                <DeepPrivacyAnalysis product={product} />
              </PrivateRoute>
            }
          />

          <Route
            path="/privacy-scan"
            element={
              <PrivateRoute authenticated={authenticated}>
                <PrivacyScan product={product} />
              </PrivateRoute>
            }
          />
          <Route
            path="/privacy-risk-report"
            element={
              <PrivateRoute authenticated={authenticated}>
                <PrivacyRiskReport product={product} />
              </PrivateRoute>
            }
          />
          <Route
            path="/setupinstruction"
            element={
              <PrivateRoute authenticated={authenticated}>
                <SetupInstruction currentUser={currentUser} />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </div>
      <div className="app-footer">
        <Footer />
      </div>
    </div>
  );
}

export default App;
