import { PRIMARY_DNS } from "../constants";

export const getTooltipsUniqueThreats = () => {
  let uniqueThreatsStr =
    "The number of unique privacy or malicious intrusions such as malware, privacy violating cookies, trackers, beacons, pixel trackers, ad networks and data brokers blocked by Kavalan in the last 24 hours.";

  return uniqueThreatsStr;
};

export const getTooltipsAccessAttempts = () => {
  let accessAttemptsStr =
    "Each kind of privacy or malicious intrusion may have been attempted more than once. This number captures the total number of privacy violating or malicious intrusions blocked by Kavalan in the last 24 hours.";

  return accessAttemptsStr;
};

export const getTooltipsMalicious = () => {
  let maliciousDomainsStr =
    " The number of malicious intrusion attempts such as malware, botnets, keyloggers, phishing, etc attempts blocked by Kavalan in the last 24 hours. However, it is possible this communication may also be from malware that is already on one or more of your devices. Action may still be needed on your side to remove malware from devices, if any. Please reach out to the Kavalan support team for help if this number is greater than 0.";

  return maliciousDomainsStr;
};

export const getTooltipsScreenRecording = () => {
  let screenRecordingStr =
    "The number of sites that Kavalan detected where screen recording technologies were used to record your actions on the screen. This may include your mouse clicks, mouse movement, what you typed and more. Please note that some websites use screen recording as a valid method of anti-fraud defense. That being said, it is important to be aware if screen recording is active and if so, to be careful about what information you reveal on that specific site.";

  return screenRecordingStr;
};

export const getTooltipsKeyStroke = () => {
  let keyStrokeStr =
    "The number of sites Kavalan detected where keystroke logging technologies may have been used. Any text you typed including user names, passwords or any content could have been captured. Very often companies use third party software for this purpose and there is added risk that the information may have been shared with a third party.";

  return keyStrokeStr;
};

export const getTooltipsAppsNoPrivacy = () => {
  let privacyPolicyStr =
    "This indicates the number of apps that Kavalan detected were used in the last 24 hours where the app did not have a privacy policy listed. When there is no privacy policy listed, there is no legal restriction that the specific app maker is required to adhere to in terms of protecting any of your data. Such apps are considered highly risky to your privacy and your data.";

  return privacyPolicyStr;
};

export const getTooltipsUniqueDomains = () => {
  let uniqueDomainsStr =
    "This number is purely for informational purposes to give you a sense of the total amount of internet activity in the last 24 hours. This number indicates the total number of unique web domains that were accessed by devices in the last 24 hours. This count includes domains that were both blocked as well as allowed by Kavalan. With about 20 connected devices, it is not unreasonable to see well over 2000 unique domains on any given day.";

  return uniqueDomainsStr;
};

export const getTooltipsWebDomains = () => {
  let webDomainsStr =
    "This number is for informational purposes as well. Connected devices continue to talk to the internet even after we sleep and very often this is essential for the functioning of the device or app including for updates/patches. This data gives you some insight into how many times devices in your network make internet requests between the 10pm - 6am window in any 24 hour period.";

  return webDomainsStr;
};

export const getTooltipsTotalWebDomains = () => {
  let totalWebDomainsStr =
    "This number is for informational purposes to give you a sense of the total amount of activity on your network. This number gives you a sense of the total volume of internet traffic in your network from devices, apps and online services used in the last 24 hours.";

  return totalWebDomainsStr;
};

export const getTooltipsAppsBlocked = () => {
  let appsBlockedStr =
    "The total number of apps that you have chosen to block on your network. To unblock any specific app, please click on the ‘Block/Unblock An App’ button. This feature is available only to Kavalan Standard users.";

  return appsBlockedStr;
};

export const getTooltipsWebDomainsBlocked = () => {
  let webDomainsBlockedStr =
    "The total number of web domains that you have chosen to block on your network in order to personalize internet activity in your home. To unblock any specific web domain, please click on the ‘Block/Unblock A Web Domain’ button. This feature is available only to Kavalan Standard users.";

  return webDomainsBlockedStr;
};

export const getTooltipsAdultSitesBlocked = () => {
  let adultSitesBlockedStr =
    "This indicates whether adult content is blocked by Kavalan across all of the devices in your network. To block or unblock adult content, please click on the ‘Block/Unblock Adult Content’ button. One click to block, one click to unblock.";

  return adultSitesBlockedStr;
};

// Setup.js and SetupInstructions.js Lists
export const verizonList = () => {
  return (
    <ol type="1">
      <li>
        Log into your home router. For Verizon FIOS, you could do this by going
        to either{" "}
        <a href="https://myfiosgateway.com/">https://myfiosgateway.com</a> or{" "}
        <a href="https://192.168.1.1/">https://192.168.1.1</a>
      </li>
      <br></br>
      <li>
        Click the <span style={{ fontWeight: "bold" }}>‘Advanced’</span> tab
      </li>
      <br></br>
      <li>
        Click <span style={{ fontWeight: "bold" }}>‘Network Settings’</span>
      </li>
      <br></br>
      <li>
        Click <span style={{ fontWeight: "bold" }}>‘Network Connections’</span>
      </li>
      <br></br>
      <li>
        Click{" "}
        <span style={{ fontWeight: "bold" }}>
          ‘Broadband Connection(Ethernet/Coax’)
        </span>
      </li>
      <br></br>
      <li>
        Scroll down to the bottom and click{" "}
        <span style={{ fontWeight: "bold" }}>‘Settings’</span>
      </li>
      <br></br>
      <li>
        Scroll to the{" "}
        <span style={{ fontWeight: "bold" }}>‘WAN IP Address’</span> section{" "}
      </li>
      <br></br>
      <li>
        From the <span style={{ fontWeight: "bold" }}>‘IPv4 DNS’</span> setting
        drop down menu select{" "}
        <span style={{ fontWeight: "bold" }}>
          ‘Use the following IPv4 DNS Addresses’
        </span>
      </li>
      <br></br>
      <li>
        Carefully note down any existing IP addresses that are in the{" "}
        <span style={{ fontWeight: "bold" }}>‘IPv4 DNS Address 1’</span> field.{" "}
      </li>
      <br></br>
      <li>
        In the <span style={{ fontWeight: "bold" }}>‘IPv4 DNS Address 1’</span>
        &nbsp;field enter :{" "}
        <span style={{ fontWeight: "bold" }}>{PRIMARY_DNS}</span>
      </li>
      <br></br>
      <li>
        In the <span style={{ fontWeight: "bold" }}>‘IPv4 DNS Address 2’</span>{" "}
        &nbsp;field enter that address that you noted down above in step 9.
        Alternatively, you could also use a third party DNS provider here like
        CloudFlare (1.1.1.1) or Google (8.8.8.8), if you so wish
      </li>
      <br></br>
      <li>
        Click <span style={{ fontWeight: "bold" }}>‘Apply’</span> and reboot the
        router to ensure the changes took effect.
      </li>
    </ol>
  );
};

export const xfinityList = () => {
  return (
    <div className="text-block-37">
      The Comcast XFinity router, unfortunately, <span>CANNOT</span> be used in
      conjunction with <span>Kavalan </span>since
      <span> </span>Comcast blocks their customers from changing the DNS
      settings on their home internet routers. <br />
      <br />
      <span className="bold-text-73">
        But, fear not! You still have options!
        <br />
      </span>
      <br />
      <span style={{ fontWeight: "bold" }}>Option 1</span>
      <br />
      You can set up device by device. <br />
      Since Kavalan is for unlimited devices, you can configure the DNS settings
      on as many devices as you'd like. <br />
      Please click on the '
      <span style={{ fontWeight: "bold" }}>
        I want to set up device by device
      </span>
      ' tab for instructions
      <br />
      <br />
      <span style={{ fontWeight: "bold" }}>Option 1</span>
      <br />
      Use a third party router in conjunction with your XFinity Router:
      <br />
      <ol type="1">
        <li>
          you will need to set your Comcast XFinity Router in '
          <span style={{ fontWeight: "bold" }}>bridge mode</span>' and then
        </li>
        <li>
          configure your third party router with{" "}
          <span style={{ fontWeight: "bold" }}>{PRIMARY_DNS}</span> as your
          Primary DNS.
        </li>
      </ol>
      <br />
      Please refer to Comcast Xfinity's router documentation for instructions to
      do this or please{" "}
      <a
        href="mailto:info@nandisecurity.com?subject=Request%20for%20router%20set%20up%20help"
        className="link-9"
      >
        contact us
      </a>{" "}
      for help to set up. <br />‍
    </div>
  );
};

export const netgearList = () => {
  return (
    <ol type="1">
      <li>
        Login to your Netgear router using your administrator credentials. You
        can usually do this by navigating to{" "}
        <a href="http://routerlogin.net" className="link-9">
          http://routerlogin.net
        </a>{" "}
        or
        <a href=" http://192.168.1.1" className="link-9">
          {" "}
          http://192.168.1.1{" "}
        </a>
      </li>
      <br />
      <li>
        Click on the <span style={{ fontWeight: "bold" }}>'Internet'</span> link
      </li>
      <br />
      <li>
        Find and navigate to the section for{" "}
        <span style={{ fontWeight: "bold" }}>
          'Domain Name Servers (DNS) Address'
        </span>
      </li>
      <br />
      <li>Note down carefully any existing DNS Address entry</li>
      <br />
      <li>
        Enter '<span style={{ fontWeight: "bold" }}>{PRIMARY_DNS}</span>' as
        the&nbsp;
        <span style={{ fontWeight: "bold" }}>'Primary DNS'</span> address
      </li>
      <br />
      <li>
        Enter any previous address that you noted down as the{" "}
        <span style={{ fontWeight: "bold" }}>'Secondary DNS'</span> address.
        Alternatively, you could use third party DNS providers such as
        CloudFlare (1.1.1.1) or Google (8.8.8.8) as your{" "}
        <span style={{ fontWeight: "bold" }}>'Secondary DNS</span>'. Please make
        sure you do <span style={{ fontWeight: "bold" }}>NOT</span> leave the{" "}
        <span style={{ fontWeight: "bold" }}>'Secondary DNS'</span> address
        blank.
      </li>
      <br />
      <li>Save/Apply the changes</li>
      <br />
      <li>Please reboot the router to ensure the changes took effect.</li>
    </ol>
  );
};

export const linksysList = () => {
  return (
    <div>
      The official instructions from Linksys can be found{" "}
      <a
        href="https://www.linksys.com/us/support-article?articleNum=316999"
        className="link-9"
      >
        here
      </a>
      . Alternatively, please following the steps below:
      <br />
      <br />
      <ol type="1">
        <li>
          Login to your home internet router using your administrator
          credentials. You can do this by navigating to{" "}
          <a href="http://192.168.1.1" className="link-9">
            http://192.168.1.1
          </a>{" "}
          or{" "}
          <a href="http://myrouter.local" className="link-9">
            http://myrouter.local
          </a>
        </li>
        <br />
        <li>
          Once logged in, click on '
          <span style={{ fontWeight: "bold" }}>Connectivity</span>'
        </li>
        <br />
        <li>
          Click on the '
          <span style={{ fontWeight: "bold" }}>Local Network</span>' sub-tab
        </li>
        <br />
        <li>
          Under '<span style={{ fontWeight: "bold" }}>DHCP Server</span>',
          please carefully note down any IP addresses that are already there
        </li>
        <br />
        <li>
          Please enter '
          <span style={{ fontWeight: "bold" }}>{PRIMARY_DNS}</span>' in the 'DNS
          Server 1' field
        </li>
        <br />
        <li>
          Please enter the previously noted down IP address in the '
          <span style={{ fontWeight: "bold" }}>DNS Server 2</span>' field. If
          there was none before then you should consider using a &nbsp;third
          party DNS provider such as Cloudflare (1.1.1.1) or Google (8.8.8.8).
          Please do <span style={{ fontWeight: "bold" }}>NOT</span> leave the '
          <span style={{ fontWeight: "bold" }}>DNS Server 2</span>' field.
        </li>
        <br />
        <li>Save/Apply the changes</li>
        <br />
        <li>Please reboot the router to ensure the changes take effect.</li>
      </ol>
    </div>
  );
};

export const tpLinkList = () => {
  return (
    <div className="text-block-40">
      TPLink may have different instructions for different router models they
      make. Please refer to their product documentation for the instructions
      most relevant to their model. FAQ for their technical questions on their
      products can be found{" "}
      <a href="https://www.tp-link.com/us/support/faq/" className="link-9">
        here
      </a>
      . <br />
      <br />
      For most models, the following steps may work:
      <br />
      <br />
      <ol type="1">
        <li>
          Login to your home internet router with the administrator credentials.
          You could do this by navigating to{" "}
          <a href="http://tplinkwifi.net" className="link-9">
            http://tplinkwifi.net
          </a>
        </li>
        <br />
        <li>
          Click on '<span style={{ fontWeight: "bold" }}>Advanced</span>'
        </li>
        <br />
        <li>
          Click on '<span style={{ fontWeight: "bold" }}>Network</span>'
        </li>
        <br />
        <li>
          Click on '<span style={{ fontWeight: "bold" }}>Internet</span>'
        </li>
        <br />
        <li>
          Click the drop down for '
          <span style={{ fontWeight: "bold" }}>Advanced</span>'
        </li>
        <br />
        <li>
          Click the radio button for '
          <span style={{ fontWeight: "bold" }}>
            Use the following DNS address
          </span>
          '
        </li>
        <br />
        <li>
          Note down carefully any IP&nbsp;address that is already there in the '
          <span style={{ fontWeight: "bold" }}>Primary DNS</span>' field
        </li>
        <br />
        <li>
          Enter
          <span style={{ fontWeight: "bold" }}> {PRIMARY_DNS}</span> in the '
          <span style={{ fontWeight: "bold" }}>Primary DNS</span>' field
        </li>
        <br />
        <li>
          Enter the previously noted down IP&nbsp;address into the '
          <span style={{ fontWeight: "bold" }}>Secondary DNS</span>' field. If
          there was none, you could use a third party DNS provider's address
          such as CloudFlare (1.1.1.1) or Google (8.8.8.8). Please do{" "}
          <span style={{ fontWeight: "bold" }}>NOT</span> leave the '
          <span style={{ fontWeight: "bold" }}>Secondary DNS</span>' field
          blank.
        </li>
        <br />
        <li>Save your changes</li>
        <br />
        <li>Reboot the router for the settings to take effect.</li>
      </ol>
    </div>
  );
};

export const asusList = () => {
  return (
    <div className="text-block-41">
      Instructions from Asus on how to set DNS server settings on your home
      internet router are available in your router documentation or{" "}
      <a href="https://www.asus.com/support/FAQ/1045253/" className="link-9">
        here
      </a>
      . We have captured them here for your convenience as well:
      <br />
      <br />
      <ol type="1">
        <li>
          Login to your router using the administrator credentials. You can do
          this by navigating to{" "}
          <a href="http://router.asus.com" className="link-9">
            http://router.asus.com
          </a>
        </li>
        <br />
        <li>
          Once logged in, click on the '
          <span style={{ fontWeight: "bold" }}>Wan</span>' link
        </li>
        <br />
        <li>
          Click on '
          <span style={{ fontWeight: "bold" }}>Internet Connection</span>' tab
        </li>
        <br />
        <li>
          In the '<span style={{ fontWeight: "bold" }}>WAN DNS</span>' setting,
          change the '
          <span style={{ fontWeight: "bold" }}>
            Connect to DNS server automatically
          </span>
          ' to '<span style={{ fontWeight: "bold" }}>No</span>'
        </li>
        <br />
        <li>
          Carefully note down if there are any existing IP addresses in the '
          <span style={{ fontWeight: "bold" }}>Primary DNS</span>' server field.
        </li>
        <br />
        <li>
          Enter '<span style={{ fontWeight: "bold" }}>{PRIMARY_DNS}</span>' as
          the '<span style={{ fontWeight: "bold" }}>Primary DNS</span>' server
          i.e. first on the list
        </li>
        <br />
        <li>
          Enter any previously noted down IP&nbsp;address into the '
          <span style={{ fontWeight: "bold" }}>Secondary DNS</span>' server
          field. Alternatively, you can use a third party DNS provider such as
          CloudFlare (1.1.1.1) or Google (8.8.8.8) as your '
          <span style={{ fontWeight: "bold" }}>Secondary DNS</span>'. We
          strongly recommend <span style={{ fontWeight: "bold" }}>NOT</span>{" "}
          leaving the 'Secondary DNS' server field blank
        </li>
        <br />
        <li>Save the changes</li>
        <br />
        <li>Reboot the router for the changes to take effect.</li>
      </ol>
    </div>
  );
};

export const appleDevice = () => {
  return (
    <div className="rich-text-block w-richtext">
      <p>‍</p>
      <h4 className="heading-16">For iOS Devices</h4>
      <ol type="1">
        <li>
          Open '<span style={{ fontWeight: "bold" }}>Settings</span>'
        </li>
        <br />
        <li>
          Tap '<span style={{ fontWeight: "bold" }}>Wi-Fi</span>'
        </li>
        <br />
        <li>
          Tap the '<span style={{ fontWeight: "bold" }}>i</span>' icon next to
          your home Wi-Fi network.
        </li>
        <br />
        <li>
          Tap '<span style={{ fontWeight: "bold" }}>Configure DNS</span>'
        </li>
        <br />
        <li>
          Tap '<span style={{ fontWeight: "bold" }}>Manual</span>'
        </li>
        <br />
        <li>
          Tap '<span style={{ fontWeight: "bold" }}>Add Server</span>' and enter{" "}
          {PRIMARY_DNS} - this is the Kavalan DNS service IP address
        </li>
        <br />
        <li>
          Move {PRIMARY_DNS} to the top of the list to make it the Primary DNS
          Server
        </li>
        <br />
        <li>
          Please make sure to leave any existing DNS server entries below the
          Kavalan DNS service IP&nbsp;address. You could also use Google
          (8.8.8.8) or CloudFlare (1.1.1.1) or any other DNS provider of your
          choice as the backup DNS. Please do not leave the backup DNS setting
          blank.
        </li>
        <br />
        <li>
          Click '<span style={{ fontWeight: "bold" }}>Save</span>'
        </li>
        <br />
      </ol>
    </div>
  );
};

export const androidDevice = () => {
  return (
    <div className="rich-text-block-2 w-richtext">
      <ol type="1">
        <li>
          Go to <span style={{ fontWeight: "bold" }}>Settings</span> &gt;{" "}
          <span style={{ fontWeight: "bold" }}>Network &amp; Internet</span>{" "}
          &gt; <span style={{ fontWeight: "bold" }}>Advanced</span> &gt;{" "}
          <span style={{ fontWeight: "bold" }}>Private DNS</span>.
        </li>
        <br />
        <li>
          Select{" "}
          <span style={{ fontWeight: "bold" }}>
            Private DNS provider hostname
          </span>
          .
        </li>
        <br />
        <li>
          Enter <span style={{ fontWeight: "bold" }}>{PRIMARY_DNS}</span> as the
          hostname of the DNS provider.
        </li>
        <br />
        <li>
          Enter Google (8.8.8.8) or CloudFlare (1.1.1.1) or any other DNS
          provider as your backup or secondary DNS. Please do not leave the
          backup DNS setting blank
        </li>
        <br />
        <li>Click Save.</li>
        <br />
      </ol>
    </div>
  );
};

export const windowsDevice = () => {
  return (
    <div className="rich-text-block-3 w-richtext">
      <ol type="1">
        <li>
          Go to the <span style={{ fontWeight: "bold" }}>Control Panel</span>.
        </li>
        <br />
        <li>
          Click <span style={{ fontWeight: "bold" }}>Network and Internet</span>{" "}
          &gt;{" "}
          <span style={{ fontWeight: "bold" }}>Network and Sharing Center</span>{" "}
          &gt;{" "}
          <span style={{ fontWeight: "bold" }}>Change adapter settings</span>.
        </li>
        <br />
        <li>Select the home Wi-Fi connection </li>
        <br />
        <li>
          To change the settings for an Ethernet connection, right-click the
          Ethernet interface and select{" "}
          <span style={{ fontWeight: "bold" }}>Properties</span>.
        </li>
        <br />
        <li>
          To change the settings for a wireless connection, right-click the
          Wi-Fi interface and select{" "}
          <span style={{ fontWeight: "bold" }}>Properties</span>.
        </li>
        <br />
        <li>
          If you are prompted for an administrator password or confirmation,
          type the password or provide confirmation.
        </li>
        <br />
        <li>
          Select the <span style={{ fontWeight: "bold" }}>Networking</span> tab.
          Under '
          <span style={{ fontWeight: "bold" }}>
            This connection uses the following items
          </span>
          ', select{" "}
          <span style={{ fontWeight: "bold" }}>
            Internet Protocol Version 4 (TCP/IPv4)
          </span>{" "}
          and then click <span style={{ fontWeight: "bold" }}>Properties</span>.
        </li>
        <br />
        <li>
          Click <span style={{ fontWeight: "bold" }}>Advanced</span> and select
          the <span style={{ fontWeight: "bold" }}>DNS</span> tab. If there are
          any DNS server IP addresses listed there, write them down for future
          reference, and remove them from this window.
        </li>
        <br />
        <li>
          Click <span style={{ fontWeight: "bold" }}>OK</span>.
        </li>
        <br />
        <li>
          Select <span>Use the following DNS server addresses</span>. If there
          are any IP addresses listed in the{" "}
          <span style={{ fontWeight: "bold" }}>Preferred DNS Server</span> or{" "}
          <span style={{ fontWeight: "bold" }}>Alternate DNS Server</span>,
          write them down for future reference.
        </li>
        <br />
        <li>
          Replace the IPv4{" "}
          <span style={{ fontWeight: "bold" }}>Preferred DNS</span> server with{" "}
          {PRIMARY_DNS}
        </li>
        <br />
        <li>
          Enter any previously noted down DNS server address as the{" "}
          <span style={{ fontWeight: "bold" }}>
            Alternate DNS&nbsp;Server.{" "}
          </span>
          Please do not leave this field blank. You could also use Google
          (8.8.8.8) or CloudFlare (1.1.1.1) or any other DNS provider of your
          choice if there was no previous value.
        </li>
        <br />
      </ol>
    </div>
  );
};
