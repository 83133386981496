import React, { useState, useEffect } from "react";
import { is_user_active } from "../common/Utils";
import { Link, useNavigate } from "react-router-dom";
import {
  androidDevice,
  appleDevice,
  asusList,
  linksysList,
  netgearList,
  tpLinkList,
  verizonList,
  windowsDevice,
  xfinityList,
} from "../common/helperFunctions";

function SetupInstruction({ currentUser }) {
  const [routerBrand, setRouterBrand] = useState(true);
  const [device, setDevice] = useState(false);
  const [apple, setApple] = useState(false);
  const [android, setAndroid] = useState(false);
  const [windows, setWindows] = useState(false);

  const [verizonVisible, setVerizonVisible] = useState(true);
  const [xfinityVisible, setXfinityVisible] = useState(false);
  const [netgearVisible, setNetgearVisible] = useState(false);
  const [linksysVisible, setLinksysVisible] = useState(false);
  const [tpLinkVisible, setTPLinkVisible] = useState(false);
  const [AsusVisible, setAsusVisible] = useState(false);
  const navigate = useNavigate();

  const showHideISP = (isp) => () => {
    setApple(false);
    setAndroid(false);
    setWindows(false);

    setVerizonVisible(false);
    setXfinityVisible(false);
    setNetgearVisible(false);
    setLinksysVisible(false);
    setTPLinkVisible(false);
    setAsusVisible(false);

    switch (isp) {
      case "verizon":
        setVerizonVisible(true);
        break;
      case "xfinity":
        setXfinityVisible(true);
        break;
      case "netgear":
        setNetgearVisible(true);
        break;
      case "linksys":
        setLinksysVisible(true);
        break;
      case "tpLink":
        setTPLinkVisible(true);
        break;
      case "asus":
        setAsusVisible(true);
        break;
      case "routerBrand":
        setRouterBrand(true);
        setDevice(false);
        setVerizonVisible(true);
        break;
      case "device":
        setDevice(true);
        setRouterBrand(false);
        setApple(true);
        break;
      case "apple":
        setApple(true);
        break;
      case "android":
        setAndroid(true);
        break;
      case "windows":
        setWindows(true);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (!is_user_active(currentUser)) {
      navigate("/login");
    }
  });

  return (
    <div className="instructions-section wf-section">
      <div
        id="Set-up-Instructions-Container"
        className="w-layout-blockcontainer setup-container w-container"
      ></div>
      <h2 className="heading-9">
        <span className="bold-text-92">Self Service Instructions</span>
      </h2>
      <div className="text-block-116">Select your set up option</div>
      <div class="w-layout-blockcontainer container-9 w-container">
        <div
          data-current=""
          data-easing="ease-in"
          data-duration-in="300"
          data-duration-out="100"
          className="tabs-5 w-tabs"
        >
          <div className="tabs-menu-5 w-tab-menu">
            <button
              data-w-tab="Router Set Up"
              className={`tab-link-router-set-up w-inline-block w-tab-link  ${
                routerBrand ? "w--current" : ""
              }`}
              onClick={showHideISP("routerBrand")}
            >
              <div className="text-block-201">
                I want to set up on my router
              </div>
            </button>

            <button
              data-w-tab="Device by device Set up"
              className={`tab-link-device-by-device-set-up w-inline-block w-tab-link  ${
                device ? "w--current" : ""
              }`}
              onClick={showHideISP("device")}
            >
              <div className="text-block-201">
                I want to set up device by device
              </div>
            </button>
          </div>

          <div className="tabs-content-5 w-tab-content">
            {routerBrand ? (
              <div>
                <h3 class="heading-77">
                  Select your router brand for set up instructions
                </h3>
                <div
                  data-current=""
                  data-easing="ease-in"
                  data-duration-in={300}
                  data-duration-out={100}
                  className="tabs-2 w-tabs"
                >
                  <div
                    id="set-up-instruction"
                    className="tabs-menu-2 w-tab-menu"
                  >
                    <button
                      data-w-tab="Verizon"
                      className={`tab-link-verizon w-inline-block w-tab-link ${
                        verizonVisible ? "w--current" : ""
                      }`}
                      onClick={showHideISP("verizon")}
                    >
                      <div className="text-block-36">Verizon</div>
                    </button>
                    <button
                      data-w-tab="Comcast Xfinity"
                      className={`tab-link-comcast-xfinity w-inline-block w-tab-link ${
                        xfinityVisible ? "w--current" : ""
                      }`}
                      onClick={showHideISP("xfinity")}
                    >
                      <div className="text-block-36"> XFinity</div>
                    </button>
                    <button
                      data-w-tab="Netgear"
                      className={`tab-link-netgear w-inline-block w-tab-link ${
                        netgearVisible ? "w--current" : ""
                      }`}
                      onClick={showHideISP("netgear")}
                    >
                      <div className="text-block-52">Netgear</div>
                    </button>
                    <button
                      data-w-tab="Linksys"
                      className={`tab-link-linksys w-inline-block w-tab-link ${
                        linksysVisible ? "w--current" : ""
                      }`}
                      onClick={showHideISP("linksys")}
                    >
                      <div className="text-block-52">Linksys</div>
                    </button>
                    <button
                      data-w-tab="TPlink"
                      className={`tab-link-tplink w-inline-block w-tab-link ${
                        tpLinkVisible ? "w--current" : ""
                      }`}
                      onClick={showHideISP("tpLink")}
                    >
                      <div className="text-block-36">TPLink</div>
                    </button>
                    <button
                      data-w-tab="Asus"
                      className={`tab-link-asus w-inline-block w-tab-link ${
                        AsusVisible ? "w--current" : ""
                      }`}
                      onClick={showHideISP("asus")}
                    >
                      <div className="text-block-52">Asus</div>
                    </button>
                  </div>
                </div>
              </div>
            ) : device ? (
              <div>
                <h3 class="heading-77">
                  Select your device type for set up instructions
                </h3>
                <div
                  data-current=""
                  data-easing="ease-in"
                  data-duration-in={300}
                  data-duration-out={100}
                  className="tabs-2 w-tabs"
                >
                  <div
                    id="set-up-instruction"
                    className="tabs-menu-2 w-tab-menu"
                  >
                    <button
                      data-w-tab="Apple"
                      className={`tab-link-other w-inline-block w-tab-link ${
                        apple ? "w--current" : ""
                      }`}
                      onClick={showHideISP("apple")}
                    >
                      <div className="text-block-52">Apple Devices</div>
                    </button>

                    <button
                      data-w-tab="Android"
                      className={`tab-link-other w-inline-block w-tab-link ${
                        android ? "w--current" : ""
                      }`}
                      onClick={showHideISP("android")}
                    >
                      <div className="text-block-52">Android Devices</div>
                    </button>

                    <button
                      data-w-tab="Window"
                      className={`tab-link-other w-inline-block w-tab-link ${
                        windows ? "w--current" : ""
                      }`}
                      onClick={showHideISP("windows")}
                    >
                      <div className="text-block-52">Windows Devices</div>
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="tabs-content-2 w-tab-content">
              <div
                data-w-tab="Verizon"
                className={`tab-pane-verizon  ${
                  verizonVisible ? "" : "w-tab-pane"
                }`}
              >
                <div class="text-block-42">
                  If you are having trouble with the instructions below, please{" "}
                  <a
                    href="mailto:info@nandisecurity.com?subject=Request%20for%20router%20set%20up%20help"
                    class="link-9"
                  >
                    contact us
                  </a>{" "}
                  for help.{" "}
                </div>
                <div className="router-instructions-block w-richtext">
                  {verizonList()}
                </div>
              </div>
            </div>

            <div
              data-w-tab="Comcast Xfinity"
              className={`tab-pane-comcast-xfinity ${
                xfinityVisible ? "" : "w-tab-pane"
              }`}
            >
              <div className="text-block-42">
                If you are having trouble with the instructions below, please{" "}
                <a
                  href="mailto:info@nandisecurity.com?subject=Request%20for%20router%20set%20up%20help"
                  className="link-9"
                >
                  contact us
                </a>{" "}
                for help.{" "}
              </div>
              {xfinityList()}
            </div>
            <div
              data-w-tab="Netgear"
              className={`tab-pane-netgear ${
                netgearVisible ? "" : "w-tab-pane"
              }`}
            >
              <div className="text-block-42">
                If you are having trouble with the instructions below, please{" "}
                <a
                  href="mailto:info@nandisecurity.com?subject=Request%20for%20router%20set%20up%20help"
                  className="link-9"
                >
                  contact us
                </a>{" "}
                for help.{" "}
              </div>
              <div className="text-block-41">{netgearList()}</div>
            </div>
            <div
              data-w-tab="Linksys"
              className={`tab-pane-linksys ${
                linksysVisible ? "" : "w-tab-pane"
              }`}
            >
              <div className="text-block-42">
                If you are having trouble with the instructions below, please{" "}
                <a
                  href="mailto:info@nandisecurity.com?subject=Request%20for%20router%20set%20up%20help"
                  className="link-9"
                >
                  contact us
                </a>{" "}
                for help.{" "}
              </div>
              {linksysList()}
            </div>
            <div
              data-w-tab="TPlink"
              className={`tab-pane-linksys ${
                tpLinkVisible ? "" : "w-tab-pane"
              }`}
            >
              <div className="text-block-42">
                If you are having trouble with the instructions below, please{" "}
                <a
                  href="mailto:info@nandisecurity.com?subject=Request%20for%20router%20set%20up%20help"
                  className="link-9"
                >
                  contact us
                </a>{" "}
                for help.{" "}
              </div>
              {tpLinkList()}
            </div>

            <div
              data-w-tab="Asus"
              className={`tab-pane-linksys ${AsusVisible ? "" : "w-tab-pane"}`}
            >
              <div className="text-block-42">
                If you are having trouble with the instructions below, please{" "}
                <a
                  href="mailto:info@nandisecurity.com?subject=Request%20for%20router%20set%20up%20help"
                  className="link-9"
                >
                  contact us
                </a>{" "}
                for help.{" "}
              </div>
              {asusList()}
            </div>

            <div
              data-w-tab="Apple"
              className={`tab-pane-other ${apple ? "" : "w-tab-pane"}`}
            >
              <div className="div-block-6">
                <div className="text-block-42">
                  If you are having trouble with the instructions below, please{" "}
                  <a
                    href="mailto:info@nandisecurity.com?subject=Request%20for%20router%20set%20up%20help"
                    className="link-9"
                  >
                    contact us
                  </a>{" "}
                  for help.{" "}
                </div>
                {appleDevice()}
                <img
                  sizes="(max-width: 479px) 100vw, 311.7421875px"
                  height={250}
                  src={require("../images/apple.png")}
                  loading="lazy"
                  // srcSet="images/IMG_4117254A1824-1-p-500.jpeg 500w, images/IMG_4117254A1824-1-p-800.jpeg 800w, images/IMG_4117254A1824-1-p-1080.jpeg 1080w, images/IMG_4117254A1824-1.jpeg 1242w"
                  alt=""
                  className="image-17"
                  style={{ marginBottom: "20px" }}
                />
              </div>
            </div>
            <div
              data-w-tab="Android"
              className={`tab-pane-other ${android ? "" : "w-tab-pane"}`}
            >
              <div className="div-block-6">
                <div className="text-block-42">
                  If you are having trouble with the instructions below, please{" "}
                  <a
                    href="mailto:info@nandisecurity.com?subject=Request%20for%20router%20set%20up%20help"
                    className="link-9"
                  >
                    contact us
                  </a>{" "}
                  for help.{" "}
                </div>
                <h4 className="heading-16">
                  For Android 9 (and higher) Devices
                </h4>
                {androidDevice()}
              </div>
            </div>
            <div
              data-w-tab="Windows"
              className={`tab-pane-other ${windows ? "" : "w-tab-pane"}`}
            >
              <div className="div-block-6">
                <div className="text-block-42">
                  If you are having trouble with the instructions below, please{" "}
                  <a
                    href="mailto:info@nandisecurity.com?subject=Request%20for%20router%20set%20up%20help"
                    className="link-9"
                  >
                    contact us
                  </a>{" "}
                  for help.{" "}
                </div>
                <h4 className="heading-17">For Windows machines</h4>
                {windowsDevice()}
              </div>
            </div>
          </div>
          <Link to="/dashboard" className="button-7 w-button">
            Back to Dashboard
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SetupInstruction;
