import React from "react";
import { GOOGLE_AUTH_URL, FACEBOOK_AUTH_URL } from "../constants";
import { Navigate, useSearchParams } from "react-router-dom";

function Login({ authenticated, ...props }) {
  const [searchParams] = useSearchParams();
  let error = searchParams.get("error");

  if (authenticated) {
    return (
      <Navigate
        to={{
          pathname: "/",
          state: { from: props.location },
        }}
      />
    );
  }

  return (
    /**
     * stylings are done manually refer to documentation
     */
    <div>
      <div className="subscription-section wf-section">
        <div className="div-block-36">
          <h1 className="heading-30">
            Welcome to Kavalan Intelligent Digital Safety
          </h1>
          <h3 class="heading-70">Please Log In or Register.</h3>
          {error && (
            <div className="w-form-fail">
              <div>{error}</div>
            </div>
          )}
          <div className="socialLoginButtonWrapper">
            <div className="googleLoginButton">
              <a
                type="button"
                className="button Button googleSignIn button tertiary-alt"
                role="button"
                data-rf-test-id="googleSignIn"
                href={GOOGLE_AUTH_URL}
              >
                <span>
                  <span className="Svg google-sso googleIcon">
                    <img
                      src="images/google-logo.png"
                      width="40"
                      height="40"
                      alt="Google"
                    />
                  </span>
                  <span className="signInText">Continue with Google</span>
                </span>
              </a>
            </div>
            <div className="facebookLoginButton">
              <a
                type="button"
                className="button Button facebookSignIn button tertiary-alt"
                role="button"
                href={FACEBOOK_AUTH_URL}
              >
                <span>
                  <img
                    src="images/fb-logo.png"
                    width="30"
                    height="30"
                    alt="Facebook"
                  />
                  <span className="signInText">
                    &nbsp;Continue with Facebook
                  </span>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
