// import React from "react";

export const getDashboardData = () => {
  const jsonData = sessionStorage.getItem("dashboard_data");
  if (jsonData) {
    return JSON.parse(jsonData);
  }
  return null;
};

export const setDashboardData = (data) => {
  const jsonData = JSON.stringify(data);
  sessionStorage.setItem("dashboard_data", jsonData);
};

export const hasDashboardData = () => {
  return sessionStorage.getItem("dashboard_data") !== null;
};

export const modifyDashboardDataToSession = (key, value) => {
  const jsonDataStr = sessionStorage.getItem("dashboard_data");
  if (jsonDataStr) {
    const jsonData = JSON.parse(jsonDataStr);
    if (jsonData.metadata && jsonData.metadata.hasOwnProperty(key)) {
      jsonData.metadata[key] = value;
    }
    if (jsonData.custom && jsonData.custom.hasOwnProperty(key)) {
      jsonData.custom[key] = value;
    }
    sessionStorage.setItem("dashboard_data", JSON.stringify(jsonData));
    return JSON.stringify(jsonData);
  }
};

export const clearDashboardData = () => {
  return sessionStorage.removeItem("dashboard_data");
};

export const getLastUpdated = () => {
  const jsonData = sessionStorage.getItem("last_updated_date");
  if (jsonData) {
    return JSON.parse(jsonData);
  }
  return null;
};

export const setLastUpdated = (data) => {
  const jsonData = JSON.stringify(data);
  sessionStorage.setItem("last_updated_date", jsonData);
};

// Not used for now but could be useful in future.
//export const hasLastUpdated = () => {
//  return sessionStorage.getItem("last_updated_date") !== null;
//};
