import React, { useEffect } from "react";
import { ACCESS_TOKEN } from "../constants";
import { useLocation } from "react-router-dom";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const AuthVerify = ({ onLogout }) => {
  let location = useLocation();

  useEffect(() => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    if (accessToken) {
      const decodedJwt = parseJwt(accessToken);
      if (decodedJwt.exp * 1000 < Date.now()) {
        onLogout();
      }
    }
  }, [location]);

  return <div></div>;
};
export default AuthVerify;
