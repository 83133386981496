import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { saveSubscription, validatePromoCode, getCurrentUser } from "../api";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import UserStatusCheck from "../common/UserStatusCheck";
import ProductType from "../constants/Constants";

function Checkout({ currentUser }) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeStatus, setPromoCodeStatus] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const [nameError, setNameError] = useState("");
  const [error, setError] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [podError, setPodError] = useState(false);
  const [showCircleProg, setShowCircleProg] = useState(false);

  const NAME_REGEX = /^[a-zA-Z\- ]+$/;

  const validateName = (value) => {
    if (NAME_REGEX.test(value)) {
      setName(value);
      setNameError("");
    } else {
      setNameError("Invalid Name");
    }
  };

  const validateCode = () => {
    setInProgress(true);

    validatePromoCode(promoCode)
      .then((res) => {
        console.log(res);
        if (res.isValidCode) {
          setPromoCodeStatus("Promo Code is valid!");
        } else {
          setPromoCodeStatus("Promo Code is not valid !!");
        }
      })
      .catch((error) => {
        setError(true);
      })
      .finally(setInProgress(false));
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (paymentMethod) {
      setInProgress(true);

      saveSubscription(paymentMethod.id, name, promoCode)
        .then((res) => {
          if (res.paymentStatus === "active") {
            setShowPopup(true);
            setShowCircleProg(true);
            const maxTimeout = 30000; // maximum timeout of 30 seconds in milliseconds
            setTimeout(() => {
              checkPodStatus(0, maxTimeout);
            }, 5000);
          } else {
            setError(true);
          }
        })
        .catch((error) => {
          setError(true);
        })
        .finally(() => {
          setInProgress(false);
        });
    }

    if (error) {
      console.log(error);
      setError(true);
    }
  };

  function checkPodStatus(timeout, maxTimeout) {
    if (timeout >= maxTimeout) {
      setPodError(true);
      setError(true);
      setShowCircleProg(false);
      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 5000);
      return;
    }
    getCurrentUser()
      .then((res) => {
        if (res.status === "active") {
          window.location.href = "/setup";
        } else {
          setTimeout(() => {
            checkPodStatus(timeout + 5000, maxTimeout);
          }, 5000);
        }
      })
      .catch((error) => {
        setPodError(true);
        setError(true);
        setShowCircleProg(false);
      })
      .finally(() => {
        setInProgress(false);
        // setShowCircleProg(false);
      });
  }
  // The function returns true if the user has one of the specified products, otherwise false.
  function userdata() {
    if (
      currentUser.product === ProductType.KAVALAN_LIGHT ||
      currentUser.product === ProductType.KAVALAN
    ) {
      return true;
    } else {
      return false;
    }
  }
  //This useEffect hook checks if the current user is registered and if they have one of the specified products. If not, it navigates to the login page.
  useEffect(() => {
    if (!currentUser.registered || userdata() === false) {
      navigate("/login");
    }
  });
  return (
    <div>
      <div className="subscription-section wf-section">
        <h1 className="heading-30">Process Payment</h1>
        <div className="div-block-36">
          <div className="div-38">
            <div className="form-block-5 w-form">
              <form
                id="wf-form-Subscription-Form"
                name="wf-form-Subscription-Form"
                data-name="Subscription Form"
                method="get"
                className="form-4"
              >
                <div className="columns w-row">
                  <span
                    htmlFor="name"
                    className="field-label"
                    style={{ padding: "5px" }}
                  >
                    Name
                  </span>
                  <input
                    type="text"
                    className="w-input-field"
                    style={{ padding: "5px" }}
                    maxLength="35"
                    size="35"
                    name="name"
                    data-name="Name"
                    placeholder="Name"
                    id="name"
                    required
                    onChange={(e) => validateName(e.target.value)}
                  />
                  {nameError && <p className="w-form-fail">{nameError}</p>}
                </div>

                {/* Card number tag has been added */}

                <div className="columns w-row">
                  <span htmlFor="card number" className="field-label">
                    Card Number&nbsp;
                  </span>
                  <CardElement className="card" />
                </div>
                <div>
                  <br />
                  <span htmlFor="promoCode" className="field-label">
                    Promo Code&nbsp;
                  </span>
                  <input
                    type="text"
                    className="w-input-field"
                    maxLength="20"
                    name="code"
                    data-name="code"
                    placeholder="Promo Code"
                    id="code"
                    required=""
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value)}
                  />
                  <button
                    type="button"
                    className={`pay-button ${inProgress ? "isDisabled" : ""}`}
                    disabled={inProgress || !promoCode}
                    onClick={validateCode}
                  >
                    Validate Promo Code
                  </button>
                  <span>
                    <b>{promoCodeStatus}</b>
                  </span>
                </div>
                <input
                  type="submit"
                  value="COMPLETE PAYMENT"
                  data-wait="Please wait..."
                  className={`kavalan-button w-button ${
                    !stripe || inProgress || nameError ? "isDisabled" : ""
                  }`}
                  style={{ marginLeft: "130px" }} //ADDED THIS STYLING FOR BRINGING THE BUTTON TO CENTER//
                  disabled={!stripe || inProgress || nameError}
                  onClick={handleSubmit}
                />
                {inProgress && (
                  <Box>
                    <CircularProgress />
                  </Box>
                )}
              </form>
              <div className="w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div>
              {error && (
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              )}
            </div>
            <h4 className="heading-31">
              <strong className="bold-text-82">Next Step:</strong> Set up your
              router to point to Kavalan
            </h4>
          </div>
        </div>
      </div>
      <UserStatusCheck
        showPopup={showPopup}
        showCircleProg={showCircleProg}
        podError={podError}
        plan={currentUser.productLabel}
      />
    </div>
  );
}

export default Checkout;
