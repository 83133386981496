import React from "react";
import { alpha, styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const RedGreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#1E7C1E",
    "&:hover": {
      backgroundColor: alpha("#1E7C1E", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#1E7C1E",
  },

  "& .MuiSwitch-switchBase": {
    color: "#fc9c02",
    "&:hover": {
      backgroundColor: alpha("#fc9c02", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase + .MuiSwitch-track": {
    backgroundColor: "#fc9c02",
  },
}));

const label = { inputProps: { "aria-label": "Color switch demo" } };

export default function ColorSwitch(props) {
  //const [checked, setChecked] = useState(false);

  return (
    <div className="switch-icon">
      <RedGreenSwitch
        {...label}
        checked={props.checked}
        onChange={props.onChange}
        id={props.id}
      />
    </div>
  );
}
