import React from "react";
import { Helmet } from "react-helmet";

function TutorialButton() {
  return (
    <div
      className="style-button"
      style={{ display: "flex", justifyContent: "space-around" }}
    >
      {/* <div>
        <button
          className=""
          // added button for alignment purposes
          // CSS commented out
          //add "YESTERDAY" once the feature is implemented
        ></button>
      </div> */}
      <Helmet>
        <script src="https://js.storylane.io/js/v1/storylane.js"></script>
      </Helmet>
      <button
        className="toprow-button"
        // className="tutorial-button"
        onClick={() =>
          // eslint-disable-next-line
          Storylane.Play({
            type: "popup",
            demo_type: "image",
            width: 2560,
            height: 1442,
            scale: "0.95",
            demo_url: "https://app.storylane.io/share/5pzauit2tsie",
            padding_bottom: "calc(56.33% + 27px)",
          })
        }
      >
        <i
          className="fa-regular fa-circle-play"
          style={{ marginRight: "5px" }}
        ></i>
        DASHBOARD TUTORIAL
      </button>
      {/* <p
        onClick={() =>
          // eslint-disable-next-line
          Storylane.Play({
            type: "popup",
            demo_type: "image",
            width: 2560,
            height: 1442,
            scale: "0.95",
            demo_url: "https://app.storylane.io/share/5pzauit2tsie",
            padding_bottom: "calc(56.33% + 27px)",
          })
        }
        >
        <i
          className="fa-regular fa-circle-play"
          style={{ marginRight: "5px", paddingBottom: "10px" }}
        ></i>
        Dashboard Tutorial
        </p> */}
    </div>
  );
}

export default TutorialButton;
