import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { saveAgreement, wifiAgreement } from "../api";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

function Agreement({ currentUser }) {
  const [loggedinConfirmation, setLoggedinConfirmation] = useState(false);
  const [ableToLoginConfirmation, setAbleToLoginConfirmation] = useState(false);

  const [termsPrivacyFlag, setTermsPrivacyFlag] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState(false);
  const [registerError, setRegisterError] = useState(null);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();

  const saveUserAgreement = () => {
    if (termsPrivacyFlag && loggedinConfirmation && ableToLoginConfirmation) {
      setInProgress(true);

      saveAgreement(moment.tz.guess())
        .then((res) => {
          wifiAgreement(moment.tz.guess())
            .then((res) => {
              if (res.errorMessage != null) {
                setMessage(res.errorMessage);
              } else {
                navigate("/product");
              }
            })
            .catch((error) => {
              setError(true);
            });
        })
        .catch((error) => {
          setError(true);
        })
        .finally(() => {
          setInProgress(false);
        });
    } else {
      setRegisterError("Please check all checkboxes");
    }
  };

  useEffect(() => {
    // If the user is registered navigate to login page else execute the aggrement page //

    if (currentUser.registered) {
      navigate("/login");
    }
  }, [currentUser]);

  return (
    <section className="hero-heading-right wf-section">
      <div className="container-6">
        <h1 className="heading-71">Are you on your Wi-Fi network?</h1>
        <div className="hero-wrapper">
          <div className="hero-split">
            <img
              src={require("../images/checkbox.png")}
              loading="lazy"
              alt=""
              className="shadow-two"
            />
          </div>
          <div className="hero-split">
            <div className="form-block-4 w-form">
              <form
                id="email-form"
                name="email-form"
                data-name="Email Form"
                method="get"
                className="form-3"
                data-wf-page-id="6318e3202b73be0ecc8f5b3f"
                data-wf-element-id="758c2db1-19ca-ea4d-ac80-f096d5897e73"
              >
                <div className="text-block-176">
                  Please confirm the following
                </div>
                <label className="w-checkbox checkbox-field">
                  <input
                    type="checkbox"
                    name="TOS-and-Privacy-Policy-3"
                    id="TOS-and-Privacy-Policy-3"
                    data-name="TOS And Privacy Policy 3"
                    className="w-checkbox-input checkbox-2"
                    onChange={() => setTermsPrivacyFlag(!termsPrivacyFlag)}
                  />
                  <span
                    className="checkbox-label-2 w-form-label"
                    htmlFor="TOS-and-Privacy-Policy-3"
                  >
                    I confirm I am currently logged in on my Wi-Fi network
                  </span>
                </label>
                <label className="w-checkbox checkbox-field-3">
                  <input
                    type="checkbox"
                    id="checkbox"
                    name="checkbox"
                    data-name="Checkbox"
                    required=""
                    className="w-checkbox-input"
                    onChange={() =>
                      setLoggedinConfirmation(!loggedinConfirmation)
                    }
                  />
                  <span
                    className="checkbox-label-4 w-form-label"
                    htmlFor="checkbox"
                  >
                    I confirm I am able to login to my Wi-Fi router
                  </span>
                </label>
                <label className="w-checkbox checkbox-field">
                  <input
                    type="checkbox"
                    name="TOS-and-Privacy-Policy-2"
                    id="TOS-and-Privacy-Policy-2"
                    data-name="TOS And Privacy Policy 2"
                    className="w-checkbox-input checkbox-2"
                    onChange={() =>
                      setAbleToLoginConfirmation(!ableToLoginConfirmation)
                    }
                  />
                  <span
                    className="checkbox-label-2 w-form-label"
                    htmlFor="TOS-and-Privacy-Policy-2"
                  >
                    I have read and accept the Terms of Service and Privacy
                    Policy
                  </span>
                </label>

                <button
                  className={`kavalan-button w-button ${
                    !(
                      termsPrivacyFlag &&
                      loggedinConfirmation &&
                      ableToLoginConfirmation
                    ) || inProgress
                      ? "isDisabled"
                      : ""
                  }`}
                  disabled={!termsPrivacyFlag || inProgress}
                  onClick={saveUserAgreement}
                >
                  NEXT
                </button>
                {inProgress && (
                  <Box>
                    <CircularProgress />
                  </Box>
                )}

                <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                {error ? (
                  <div className="w-form-fail">
                    <div>
                      Oops! Something went wrong while submitting the form.
                    </div>
                  </div>
                ) : registerError !== null ? (
                  <div className="w-form-fail">
                    <div>{registerError}</div>
                  </div>
                ) : message !== null ? (
                  <div className="w-form-fail">
                    <div>{message}</div>
                  </div>
                ) : null}
              </form>
            </div>
          </div>
        </div>

        <div className="div-block-74">
          <a href="https://www.getkavalan.com/tos" className="link-29">
            Terms of Service
          </a>
          <a href="https://www.getkavalan.com/privacy" className="link-28">
            Privacy Policy
          </a>
        </div>
        <div className="text-block-196">
          <strong className="bold-text-121">Next Step</strong>: Select your Plan
          - Free Trial or Subscription
        </div>
      </div>
    </section>
  );
}

export default Agreement;
