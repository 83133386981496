import React from "react";
// import { Button } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";

function RefreshButton() {
  return (
    <div className="toprow-button">
      <SyncIcon sx={{ mr: 1 }} />
      Refresh
    </div>
  );
}

export default RefreshButton;
