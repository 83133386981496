import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import PopupLayout from "./PopupLayout";

function UserStatusCheck(props) {
  return (
    <div>
      <PopupLayout show={props.showPopup}>
        {" "}
        <div>
          <div className="popup-text-block-162">
            <strong>
              Initializing {props.plan} for your home
              <br />
            </strong>
            Please wait while your resource is being created. You will be
            automatically redirected to the next page once the creation is
            completed.
            {!props.podError && props.showCircleProg && (
              <Box>
                <CircularProgress />
              </Box>
            )}
          </div>
          {props.podError && (
            <div>
              {" "}
              <div className="popup-text-block-162">
                sorry we ran into a problem while allocating the resouces, we
                will resolve the issue and the customer service will get back to
                you{" "}
              </div>
            </div>
          )}
        </div>{" "}
      </PopupLayout>
    </div>
  );
}
export default UserStatusCheck;
