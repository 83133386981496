import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./css/normalize.css";
import "./css/webflow.css";
import "./css/nandi-security-kavalan.webflow.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { STRIPE_PUBLISH_KEY } from "./constants";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const root = ReactDOM.createRoot(document.getElementById("root"));

const stripePromise = loadStripe(STRIPE_PUBLISH_KEY);
root.render(
  <BrowserRouter>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
