import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  savePaymentPlan,
  updatePaymentPlan,
  isIspUser,
  getCurrentUser,
} from "../api";
import CustomPopup from "../common/CustomPopup";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import ProductType from "../constants/Constants";
import UserStatusCheck from "../common/UserStatusCheck";

function Product({ currentUser }) {
  const [isAnnual, setIsAnnual] = useState(false);
  const [isMonthly, setIsMonthly] = useState(false);
  const [isTrial, setIsTrial] = useState(false);

  const [product, setProduct] = useState();
  const [plan, setPlan] = useState();
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState(false);
  const [ispUser, setIspUser] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isUpdate = location.state && location.state.upgrade;
  const [update, setUpdate] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [podError, setPodError] = useState(false);
  const [showCircleProg, setShowCircleProg] = useState(false);
  //eslint-disable-next-line
  const [updateSubPopup, setUpdateSubPopup] = useState(false);

  // Product pricing variable
  const kavLightMonthlyPrice = 7.99;
  const kavStandardMonthlyPrice = 11.99;
  const kavLightAnnualPrice = 60;
  const kavStandardAnnualPrice = 120;
  const kavLightPercDiscount = Math.round(
    ((kavLightMonthlyPrice * 12 - kavLightAnnualPrice) /
      (kavLightMonthlyPrice * 12)) *
      100,
  );
  const kavStandardPercDiscount = Math.round(
    ((kavStandardMonthlyPrice * 12 - kavStandardAnnualPrice) /
      (kavStandardMonthlyPrice * 12)) *
      100,
  );

  useEffect(() => {
    // If the user is not registered move to login page else executes the same page
    if (!currentUser.registered) {
      navigate("/login");
    }

    isIspUser()
      .then((res) => {
        setIspUser(res.ispUser);
        if (res.ispUser) {
          setPlan("Monthly"); // Set default plan to "Monthly" for ISP users

          setIsMonthly(true);
        } else {
          setPlan("Trial"); // Set default plan to "Trial" for normal users

          setIsTrial(true);
        }
      })
      .catch((error) => {
        setError(true);
      });
  }); //dependancy array removed due to eslint warning

  const showHidePlan = (productPlan) => () => {
    setIsAnnual(false);
    setIsMonthly(false);
    setIsTrial(false);

    setPlan(productPlan);

    if (productPlan === "Annual") {
      setIsAnnual(true);
    } else if (productPlan === "Monthly") {
      setIsMonthly(true);
    } else if (productPlan === "Trial") {
      setIsTrial(true);
    }
  };

  const UpdateSubscription = () => {
    updatePaymentPlan(product, plan, ispUser)
      .then((res) => {
        if (
          ispUser ||
          product.toLowerCase() === ProductType.KAVALAN_LIGHT.toLowerCase() ||
          product.toLowerCase() === ProductType.KAVALAN.toLowerCase()
        ) {
          if (res.message === null) {
            window.location.href = "/dashboard";
          } else {
            setError(res.message);
            setTimeout(() => {
              window.location.reload();
            }, 5000); // 20000 milliseconds = 10 seconds
          }
        } else {
          setError(res);
        }
        setUpdateSubPopup(false);
      })
      .catch((error) => {
        setError(true);
      });
  };

  const closeUpdateSubscription = () => {
    setUpdateSubPopup(false);
    window.location.reload();
  };

  const UpdateSubscriptionLayout = () => {
    return (
      <div>
        <CustomPopup show={true} onClose={closeUpdateSubscription}>
          <div>
            <div>
              <h1 className="heading-52">Alert</h1>
              <div className="text-block-185">
                Are you sure you want to Update the subscription
              </div>
            </div>
            <div className="div-block-48-confirm">
              <button
                type="button"
                onClick={UpdateSubscription}
                className="button-21-confirm w-button"
              >
                Yes
              </button>
              <button
                type="button"
                onClick={closeUpdateSubscription}
                className="button-20-confirm w-button"
              >
                No
              </button>
            </div>
            {error && <div className="w-form-fail">{error}</div>}
          </div>
        </CustomPopup>
      </div>
    );
  };

  const saveUserProduct = (userProduct) => () => {
    setInProgress(true);
    setProduct(userProduct);

    if (isUpdate === true || currentUser.status === "expired") {
      setUpdate(true);
    } else {
      savePaymentPlan(userProduct, plan, ispUser)
        .then((res) => {
          if (userProduct === "Trial") {
            window.location.href = "/setup";
          } else if (
            ispUser &&
            (userProduct.toLowerCase() === ProductType.KAVALAN.toLowerCase() ||
              userProduct.toLowerCase() ===
                ProductType.KAVALAN_LIGHT.toLowerCase())
          ) {
            setShowPopup(true);
            setShowCircleProg(true);
            const maxTimeout = 30000; // maximum timeout of 30 seconds in milliseconds
            window.location.href = "/dashboard";
            setTimeout(() => {
              checkPodStatus(0, maxTimeout);
            }, 5000);
          } else {
            window.location.href = "/checkout";
          }
        })
        .catch((error) => {
          setError(true);
        })
        .finally(() => {
          setInProgress(false);
        });
    }
  };

  function checkPodStatus(timeout, maxTimeout) {
    if (timeout >= maxTimeout) {
      setPodError(true);
      setError(true);
      setShowCircleProg(false);
      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 5000);
      return;
    }
    getCurrentUser()
      .then((res) => {
        if (res.status === "active") {
          window.location.href = "/setup";
        } else {
          setTimeout(() => {
            checkPodStatus(timeout + 5000, maxTimeout);
          }, 5000);
        }
      })
      .catch((error) => {
        setPodError(true);
        setError(true);
        setShowCircleProg(false);
      })
      .finally(() => {
        setInProgress(false);
        // setShowCircleProg(false);
      });
  }

  const AnnualDivBlock = () => (
    <div className="w-tab-pane w--tab-active">
      <div className="columns-50 w-row">
        <div className="column-99 w-col w-col-6 w-col-medium-6 w-col-small-6 w-col-tiny-tiny-stack">
          <h3 className="heading-35">
            <strong className="bold-text-86">Kavalan</strong> <br />
            Light
          </h3>
          {!ispUser && (
            <div className="text-block-150">
              ${kavLightAnnualPrice}
              <div className="text-block-198">
                Billed annually. <br />
                {kavLightPercDiscount}% savings
              </div>
            </div>
          )}

          <button
            disabled={inProgress}
            className={`kavalan-button w-button ${
              inProgress ? "isDisabled" : ""
            }`}
            onClick={saveUserProduct("KavalanLight")}
          >
            SELECT PLAN
          </button>
          {inProgress && product === "KavalanLight" && (
            <Box>
              <CircularProgress />
            </Box>
          )}
          {error && (
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          )}
        </div>
        <div className="column-100 w-col w-col-6 w-col-medium-6 w-col-small-6 w-col-tiny-tiny-stack">
          <h3 className="heading-35">
            <strong className="bold-text-86">Kavalan</strong> <br />
            Standard
          </h3>
          {!ispUser && (
            <div className="text-block-152">
              ${kavStandardAnnualPrice}
              <div className="text-block-198">
                Billed annually. <br />
                {kavStandardPercDiscount}% savings
              </div>
            </div>
          )}

          <button
            disabled={inProgress}
            className={`kavalan-button w-button ${
              inProgress ? "isDisabled" : ""
            }`}
            onClick={saveUserProduct("Kavalan")}
          >
            SELECT PLAN
          </button>
          {inProgress && product === "Kavalan" && (
            <Box>
              <CircularProgress />
            </Box>
          )}
          {error && (
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          )}
        </div>
      </div>
      <h4>
        To learn more about the product tiers, click{" "}
        <a
          href="https://www.getkavalan.com/#Product"
          target="_blank"
          rel="noreferrer"
          className="link-26"
        >
          here
        </a>
      </h4>
    </div>
  );

  const MonthlyDivBlock = () => (
    <div class="tab-pane-monthly w-tab-pane w--tab-active">
      <div className="columns-51 w-row">
        <div className="column-101 w-col w-col-6">
          <h3 className="heading-37">
            <strong className="bold-text-89">Kavalan</strong> <br />
            Light
          </h3>
          {!ispUser && (
            <div className="text-block-155">
              ${kavLightMonthlyPrice.toFixed(2)}
              <div className="text-block-199">Billed monthly</div>
            </div>
          )}

          <button
            disabled={inProgress}
            className={`kavalan-button w-button ${
              inProgress ? "isDisabled" : ""
            }`}
            onClick={saveUserProduct("KavalanLight")}
          >
            SELECT PLAN
          </button>
          {inProgress && product === "KavalanLight" && (
            <Box>
              <CircularProgress />
            </Box>
          )}
          {error && (
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          )}
        </div>
        <div className="column-102 w-col w-col-6">
          <h3 className="heading-37">
            <strong className="bold-text-89">Kavalan</strong> <br />
            Standard
          </h3>
          {!ispUser && (
            <div className="text-block-156">
              ${kavStandardMonthlyPrice}
              <div className="text-block-200">Billed monthly</div>
            </div>
          )}

          <button
            disabled={inProgress}
            className={`kavalan-button w-button ${
              inProgress ? "isDisabled" : ""
            }`}
            onClick={saveUserProduct("Kavalan")}
          >
            SELECT PLAN
          </button>
          {inProgress && product === "Kavalan" && (
            <Box>
              <CircularProgress />
            </Box>
          )}
          {error && (
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          )}
        </div>
      </div>
      <h4>
        To learn more about the product tiers, click{" "}
        <a
          href="https://www.getkavalan.com/#Product"
          target="_blank"
          rel="noreferrer"
          className="link-26"
        >
          here
        </a>
      </h4>
    </div>
  );

  const FreeTrialDivBlock = () => (
    <div className="w-tab-pane w--tab-active">
      <div className="div-38">
        <h3 className="heading-76">
          30 day free trial of{" "}
          <strong className="bold-text-123">Kavalan</strong> Light
        </h3>
        <h4 className="heading-4">Watch Before You Start</h4>
        <div
          // style={{ paddingTop: "56.17021276595745%" }}
          className="video-2 w-video w-embed"
        >
          <iframe
            className="embedly-embed"
            width="640"
            height="300"
            src="https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FmL3scvqB0VM%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DmL3scvqB0VM&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FmL3scvqB0VM%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube"
            scrolling="no"
            allowFullScreen=""
            title="Kavalan : Start my Setup"
          />
        </div>
        <div className="div-block-42">
          <button
            disabled={inProgress}
            className={`kavalan-button w-button ${
              inProgress ? "isDisabled" : ""
            }`}
            onClick={saveUserProduct("Trial")}
          >
            SET UP INSTRUCTIONS
          </button>
        </div>
        {inProgress && product === "Trial" && (
          <Box>
            <CircularProgress />
          </Box>
        )}
        {error && (
          <div className="w-form-fail">
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div>
      <div className="subscription-section wf-section">
        <div className="w-layout-blockcontainer container-10 w-container">
          <div className="cart-image">
            <img
              src={require("../images/23.png")}
              loading="lazy"
              // srcSet="images/23-p-500.png 500w, images/23-p-800.png 800w, images/23.png 1024w"
              alt="Shopping Cart"
            />
          </div>
          <h1 className="heading-24 selectYourPlanTitle">Select Your Plan</h1>
          <h3 className="heading-75">Pick a Subscription Tier or Free Trial</h3>
          <div
            data-current="Free Trial"
            data-easing="ease-in-sine"
            data-duration-in={300}
            data-duration-out={100}
            className="tabs-4 w-tabs productContent"
          >
            <div className="tabs-menu-4 w-tab-menu" role="tablist">
              {!ispUser && (
                <button
                  data-w-tab="Free Trial"
                  className={`tab-link-free-trial w-inline-block w-tab-link ${
                    isTrial ? "w--current" : ""
                  }`}
                  onClick={showHidePlan("Trial")}
                >
                  <div className="text-block-157">Free Trial</div>
                </button>
              )}
              <button
                data-w-tab="Annual"
                className={`tab-link-annual w-inline-block w-tab-link ${
                  isAnnual ? "w--current" : ""
                }`}
                onClick={showHidePlan("Annual")}
              >
                <div className="text-block-195">Annual</div>
              </button>
              <button
                data-w-tab=" Monthly"
                className={`tab-link-monthly w-inline-block w-tab-link ${
                  isMonthly ? "w--current" : ""
                }`}
                onClick={showHidePlan("Monthly")}
              >
                <div class="text-block-202">Monthly</div>
              </button>
            </div>
            <div className="tabs-content-4 w-tab-content">
              <div data-w-tab="Free Trial">
                {" "}
                {isTrial ? <FreeTrialDivBlock /> : ""}{" "}
              </div>

              <div data-w-tab="Annual">
                {" "}
                {isAnnual ? <AnnualDivBlock /> : ""}{" "}
              </div>

              <div data-w-tab="Monthly">
                {" "}
                {isMonthly ? <MonthlyDivBlock /> : ""}{" "}
              </div>
            </div>
          </div>
        </div>
        {update && <UpdateSubscriptionLayout />}
      </div>

      <UserStatusCheck
        showPopup={showPopup}
        showCircleProg={showCircleProg}
        podError={podError}
      />
    </div>
  );
}

export default Product;
