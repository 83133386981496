import React, { useState } from "react";
import { PiSpinner } from "react-icons/pi";
import OpenWithIcon from "@mui/icons-material/OpenWith";

import BlockedDomains from "./BlockedDomains";

// import "../css/nandi-security-kavalan.webflow.css";
import BlockedDomainsThumbnail from "./BlockedDomains-thumbnail";
import { Modal, Box, Button } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function BubblesCard(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      {props.mainSpinner ? (
        <div className="pageload-spinner">
          <PiSpinner
            style={{
              fontSize: "5rem",
              animation: "spin 2s linear infinite",
              marginTop: "80px",
            }}
          />
        </div>
      ) : (
        <div
          className="bubble-container chart-container"
          // style={{ backgroundColor: "red" }}
        >
          <div className="chart-title-container">
            <p className="chart-title">Blocked Domain Insights</p>
            <p className="chart-subtitle">(Blocked in the last 24 hours)</p>
          </div>

          <div
            onClick={handleOpen}
            className="bubbles"
            // style={{ backgroundColor: "lightblue"}}
          >
            <BlockedDomainsThumbnail
              domainsBlocked={props.domainsBlocked}
              uniqueMalwareDomainsBlocked={props.uniqueMalwareDomainsBlocked}
              product={props.product}
              open={open}
            />
          </div>

          <div
            className="expand-icon-bubble"
            // style={{ backgroundColor: "yellow" }}
          >
            <OpenWithIcon onClick={handleOpen} />
          </div>
        </div>
      )}

      {/* this is the pop-up for the bubbles */}
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Button
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            Close
          </Button>
          <BlockedDomains
            domainsBlocked={props.domainsBlocked}
            uniqueMalwareDomainsBlocked={props.uniqueMalwareDomainsBlocked}
            product={props.product}
          />
        </Box>
      </Modal>
    </>
  );
}

export default BubblesCard;
