//eslint-disable-next-line
import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { blackLight } from "../api";
import { parseDomain, ParseResultType, fromUrl } from "parse-domain";

function PrivacyScan(props) {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [websiteName, setwebsiteName] = useState("");
  const [adTrackersDetected, setAdTrackersDetected] = useState(null);
  const [thirdPartyCookiesDetected, setThirdPartyCookiesDetected] =
    useState(null);
  const [cookieBlockingEvasionDetected, setCookieBlockingEvasionDetected] =
    useState(null);
  const [sessionRecordingDetected, setSessionRecordingDetected] =
    useState(null);
  const [keystrokeLoggingDetected, setKeystrokeLoggingDetected] =
    useState(null);
  const [sharesInfoWithFacebook, setSharesInfoWithFacebook] = useState(null);
  const [sharesInfoWithGoogle, setSharesInfoWithGoogle] = useState(null);

  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    if (!websiteName) {
      setError("Please enter the domain");
      return;
    }

    const isValidDomain = require("is-valid-domain");
    let fullDomain = "";
    const parseResult = parseDomain(websiteName);

    if (parseResult.type === ParseResultType.Listed) {
      //eslint-disable-next-line
      const { subDomains, domain, topLevelDomains } = parseResult;
      if (domain && topLevelDomains) {
        fullDomain = `${domain}.${topLevelDomains.join(".")}`;
      } else {
        setError("InValid Domain");
        return;
      }
    } else {
      //eslint-disable-next-line
      const { subDomains, domain, topLevelDomains } = parseDomain(
        fromUrl(websiteName),
      );
      if (domain || topLevelDomains) {
        fullDomain = `${domain}.${topLevelDomains.join(".")}`;
      } else {
        setError("InValid Domain");
        return;
      }
    }

    if (!isValidDomain(fullDomain)) {
      setError("InValid Domain");
      return;
    }

    setLoading(true);
    setError(null);

    blackLight(fullDomain)
      .then((response) => {
        setAdTrackersDetected(response.ddg_join_ads.bigNumber);
        setThirdPartyCookiesDetected(response.cookies.bigNumber);
        setSharesInfoWithFacebook(
          response.fb_pixel_events.testEventsFound ? "Yes" : "No",
        );
        setSharesInfoWithGoogle(response.ga.testEventsFound ? "Yes" : "No");
        setCookieBlockingEvasionDetected(
          response.canvas_fingerprinters.testEventsFound ? "Yes" : "No",
        );
        setSessionRecordingDetected(
          response.session_recorders.testEventsFound ? "Yes" : "No",
        );
        setKeystrokeLoggingDetected(
          response.key_logging.testEventsFound ? "Yes" : "No",
        );
      })
      .catch((error) => {
        setError("Oops! We hit a snag.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (props.product !== "kavalan") {
      navigate("/Dashboard");
    }
  }, []);

  return (
    <div className="app-buttons-section">
      <div className="container-new w-container">
        <div className="div-block-88">
          <h1 className="heading-28">Scan A Website For Privacy Risks</h1>
          <div className="w-form">
            <div
              id="email-form"
              name="email-form"
              data-name="Email Form"
              method="get"
              className="form-5"
              data-wf-page-id="64ecec162bd7140df5156bfe"
              data-wf-element-id="7acb6940-4be4-cc8f-445b-97a82d963cc3"
            >
              <label htmlFor="name">
                Enter the website you would like to scan
              </label>
              <input
                type="text"
                className="text-field-3 w-input"
                maxLength={256}
                name="name"
                data-name="Name"
                placeholder="e.g. www.website.com"
                id="website-name"
                value={websiteName}
                onChange={(e) => setwebsiteName(e.target.value)}
              />
              <button
                className="button-26 w-button"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? "Scanning..." : "Scan the site"}
              </button>
              {error && <div className="w-form-fail">{error}</div>}
              {loading && !error && (
                <div className="loading-message">
                  Please wait. This process may take up to 2 minutes.
                </div>
              )}
            </div>
          </div>
        </div>
        <h1 className="heading-85">Website Privacy Scan Report</h1>
        <div className="w-layout-grid grid-5">
          <div
            id="w-node-c173414f-e48e-96ea-3d7e-8d7764ad2a27-f5156bfe"
            className="div-block-81"
          >
            <h3
              id="w-node-c6724ef9-994f-9e9f-b63a-338db16b1b0e-f5156bfe"
              className="heading-80"
            >
              {adTrackersDetected}
            </h3>
            <div className="text-block-208">Ad Trackers Detected</div>
          </div>
          <div
            id="w-node-cd4b93d9-1f5e-edbb-2583-65ca0f51be5a-f5156bfe"
            className="div-block-82"
          >
            <h3 className="heading-81">{thirdPartyCookiesDetected}</h3>
            <div className="text-block-208">Third Party Cookies Detected</div>
          </div>
          <div
            id="w-node-_2961cd32-d117-32ca-da00-68125e7bc153-f5156bfe"
            className="div-block-83"
          >
            <h3 className="heading-82">{cookieBlockingEvasionDetected}</h3>
            <div className="text-block-208">
              Cookie Blocking Evasion Detected
            </div>
          </div>
          <div
            id="w-node-beb28310-1704-a0b4-3cd1-1c877cbb8bbd-f5156bfe"
            className="div-block-84"
          >
            <h3 className="heading-81">{sessionRecordingDetected}</h3>
            <div className="text-block-208">Session Recording Detected</div>
          </div>
          <div
            id="w-node-ab795a16-2337-616d-4e08-342842d48e68-f5156bfe"
            className="div-block-85"
          >
            <h3 className="heading-81">{keystrokeLoggingDetected}</h3>
            <div className="text-block-208">Keystroke Logging Detected</div>
          </div>
          <div
            id="w-node-bc0d6fbb-cdcd-6311-aa5d-d24d649df2bd-f5156bfe"
            className="div-block-86"
          >
            <h3 className="heading-81">{sharesInfoWithFacebook}</h3>
            <div className="text-block-208">
              Shares Information With Facebook
            </div>
          </div>
          <div
            id="w-node-_4ecb0c97-3b52-b5f0-352a-a1b409e2e7f8-f5156bfe"
            className="div-block-87"
          >
            <h3 className="heading-81">{sharesInfoWithGoogle}</h3>
            <div className="text-block-208">Shares Information with Google</div>
          </div>
        </div>
        <Link to="/dashboard" className="button-26 w-button">
          Back to dashboard
        </Link>
      </div>
    </div>
  );
}

export default PrivacyScan;
