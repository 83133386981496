import React from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { ACCESS_TOKEN } from "../constants";

function OAuth2RedirectHandler() {
  const [searchParams] = useSearchParams();

  let token = searchParams.get("token");

  if (token) {
    localStorage.setItem(ACCESS_TOKEN, token);

    window.location.href = "/";

    return <Navigate replace to="/" />;
  } else {
    let error = searchParams.get("error");
    if (error) {
      window.location.href = "/login?error=" + error;
      return;
    }
    return <Navigate to="/login" />;
  }
}

export default OAuth2RedirectHandler;
