import React, { useEffect, useState } from "react";
//eslint-disable-next-line
import { blockWebsites, unblockWebsites, getWebsites } from "../api";
import { useNavigate, Link } from "react-router-dom";
import CustomPopup from "../common/CustomPopup";
import { setDashboardData, getDashboardData } from "../common/StorageUtils";

function BlockWebsites(props) {
  const [blockStatus, setBlockStatus] = useState(undefined);
  const [unBlockstatus, setUnblockStatus] = useState(undefined);
  const [blockValue, setBlockValue] = useState({
    option1: "",
    option2: "",
    option3: "",
  });
  const [blockResponse, setblockResponse] = useState(undefined);
  const [UnblockResponse, setUnblockResponse] = useState(undefined);
  const [unBlockValue, setUnBlockValue] = useState({
    option1: "",
    option2: "",
  });
  const [error, setError] = useState(false); //eslint-disable-line

  const [selectedValue, setSelectedValue] = useState("block");
  const [blockData, setBlockData] = useState(undefined);
  const [blockMessage, setBlockMessage] = useState("");
  const [unBlockMessage, setUnBlockMessage] = useState("");
  const [unBlockData, setUnBlockData] = useState(undefined);
  const [showPopup, setShowPopup] = useState(null);
  const [lastAccessedDomains, setLastAccessedDomains] = useState(undefined);

  const [blockOption, setBlockOption] = useState("Previously Accessed Domain");
  const [unblockOption, setUnBlockOption] = useState("Option 1");

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (props.product !== "kavalan") {
      navigate("/Dashboard");
    } else {
      getAllWebsites();
    }
  }, []);

  const getAllWebsites = () => {
    getWebsites()
      .then((response) => {
        setBlockData(response.blocked);
        setUnBlockData(response.unBlocked);
        setLastAccessedDomains(response.lastAccessedDomains);
      })
      .catch((error) => {
        setError(true);
      });
  };

  const blockDomain = () => {
    setShowPopup(null);
    const domainToBlock = getSelectedBlockValue();
    if (domainToBlock) {
      blockWebsites(domainToBlock, true)
        .then((res) => {
          if (res.websiteStatus) {
            //retrieve the current value of the SessionStorage:
            const sessionStorageValue = getDashboardData();

            if (sessionStorageValue !== null) {
              //update the current number of the blocked apps in the SessionStorage value:
              sessionStorageValue.custom.noOfWebsitesBlocked += 1;

              //call a function that will put an updated value to the Session Storage:
              setDashboardData(sessionStorageValue);
            }
            setBlockStatus({ type: "success" });
            //setBlockValue("");
            setSelectedBlockValue("");
            //setUnBlockValue("");
            setUnBlockData(undefined);
            setBlockData(undefined);
            setShowPopup(null);
            setblockResponse(
              "The '" +
                domainToBlock +
                "' has been successfully blocked. '" +
                domainToBlock +
                "'  may still be cached by devices, apps, and browsers. To clear the caches and ensure that the blocking is effective, please restart any relevant apps, devices, or browsers.",
            );
            getAllWebsites();
          } else {
            setBlockStatus({ type: "unChanged" });
            setBlockValue("");
            setShowPopup(null);
            setBlockMessage(res.message);
          }
        })
        .catch((error) => {
          setBlockStatus({ type: "error" });
          setError(true);
          setBlockValue("");
        });
    } else if (blockValue === undefined) {
      setBlockStatus({ type: "error" });
      setBlockValue("");
    }
  };
  const unBlockDomain = () => {
    setShowPopup(null);
    const domainToUnBlock = getSelectedUnblockValue();
    if (domainToUnBlock) {
      blockWebsites(domainToUnBlock, false)
        .then((res) => {
          if (!res.websiteStatus && unBlockValue !== undefined) {
            //retrieve the current value of the SessionStorage:
            const sessionStorageValue = getDashboardData();

            if (sessionStorageValue !== null) {
              //update the current number of the blocked apps in the SessionStorage value:
              sessionStorageValue.custom.noOfWebsitesBlocked -= 1;

              //call a function that will put an updated value to the Session Storage:
              setDashboardData(sessionStorageValue);
            }
            setUnblockStatus({ type: "success" });
            setUnblockResponse(domainToUnBlock + " has been Unblocked!");
            //setUnBlockValue("");
            //setBlockValue("");
            setSelectedUnblockValue("");
            setBlockData(undefined);
            setUnBlockData(undefined);
            getAllWebsites();
          } else {
            setUnblockStatus({ type: "unChanged" });
            setUnBlockValue("");
            setUnBlockMessage(res.message);
          }
        })
        .catch((error) => {
          setError(true);
          setUnblockStatus({ type: "error" });
          setUnBlockValue("");
        });
    } else if (unBlockValue === undefined) {
      setUnblockStatus({ type: "error" });
      setUnBlockValue("");
    }
  };

  const getSelectedBlockValue = () => {
    if (blockOption === "Previously Accessed Domain") return blockValue.option1;
    if (blockOption === "Previously unblocked") return blockValue.option2;
    if (blockOption === "Freeform block") return blockValue.option3;
  };

  const setSelectedBlockValue = (value) => {
    if (blockOption === "Previously Accessed Domain")
      return setBlockValue((prev) => {
        return { ...prev, option1: value };
      });
    if (blockOption === "Previously unblocked")
      return setBlockValue((prev) => {
        return { ...prev, option2: value };
      });
    if (blockOption === "Freeform block")
      return setBlockValue((prev) => {
        return { ...prev, option3: value };
      });
  };

  const getSelectedUnblockValue = () => {
    if (unblockOption === "Option 1") return unBlockValue.option1;
    if (unblockOption === "Option 2") return unBlockValue.option2;
  };

  const setSelectedUnblockValue = (value) => {
    if (unblockOption === "Option 1")
      return setUnBlockValue((prev) => {
        return { ...prev, option1: value };
      });
    if (unblockOption === "Option 2")
      return setUnBlockValue((prev) => {
        return { ...prev, option2: value };
      });
  };

  const clearBlockData = () => {
    setBlockStatus({ type: undefined });
  };

  const clearUnBlockData = () => {
    setUnblockStatus({ type: undefined });
  };

  const handleOnClose = (state) => {
    setShowPopup(state);
  };

  const renderBlockOptions = () => {
    return (
      <div className="div-block-79">
        <h3 className="heading-68">Select an option to block a web domain</h3>
        <div
          data-current="Previously Accessed Domain"
          data-easing="ease"
          data-duration-in={300}
          data-duration-out={100}
          className="block-options-tab w-tabs"
        >
          <div className="tabs-menu-6 w-tab-menu">
            <button
              type="button"
              data-w-tab="Previously Accessed Domain"
              className={`tab-link-tab-1 w-inline-block w-tab-link ${
                blockOption === "Previously Accessed Domain" ? "w--current" : ""
              }`}
              onClick={() => setBlockOption("Previously Accessed Domain")}
            >
              <div className="text-block-203">Option 1</div>
            </button>
            <button
              type="button"
              data-w-tab="Previously unblocked"
              className={`tab-link-tab-2 w-inline-block w-tab-link ${
                blockOption === "Previously unblocked" ? "w--current" : ""
              }`}
              onClick={() => setBlockOption("Previously unblocked")}
            >
              <div className="text-block-205">Option 2</div>
            </button>
            <button
              type="button"
              className={`tab-link-tab-3 w-inline-block w-tab-link ${
                blockOption === "Freeform block" ? "w--current" : ""
              }`}
              onClick={() => setBlockOption("Freeform block")}
            >
              <div className="text-block-206">Option 3</div>
            </button>
          </div>
          <div className="w-tab-content">
            {blockOption === "Previously Accessed Domain" && (
              <div
                data-w-tab="Previously Accessed Domain"
                className="tab-pane-previously-accessed-domain w-tab-pane w--tab-active"
              >
                <div className="drop-down-list-div-block">
                  <div className="text-block-190">
                    Select from the drop down menu a domain previously accessed
                    (last 24 hours)
                  </div>

                  {lastAccessedDomains !== undefined ? (
                    <select
                      className="select-field w-select"
                      onChange={(e) => setSelectedBlockValue(e.target.value)}
                      value={blockValue.option1}
                    >
                      <option key="" value="">
                        Select a Domain
                      </option>
                      {lastAccessedDomains.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <select className="select-field w-select">
                      <option key="" value="">
                        No Data
                      </option>
                    </select>
                  )}
                </div>
              </div>
            )}

            {blockOption === "Previously unblocked" && (
              <div
                data-w-tab="Previously unblocked"
                className="tab-pane-tab-2 w-tab-pane w--tab-active"
              >
                <div className="text-block-42-new">
                  Select a domain from the list of previously manually unblocked
                  domains{" "}
                </div>
                {unBlockData !== undefined ? (
                  <select
                    className="select-field w-select"
                    onChange={(e) => setSelectedBlockValue(e.target.value)}
                    value={blockValue.option2}
                  >
                    <option key="" value="">
                      Select a domain
                    </option>
                    {unBlockData.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                ) : (
                  <select className="select-field w-select">
                    <option key="" value="">
                      No Data
                    </option>
                  </select>
                )}
              </div>
            )}
            {blockOption === "Freeform block" && (
              <div
                data-w-tab="Freeform block"
                className="tab-pane-freeform-block w-tab-pane  w--tab-active"
              >
                <div className="div-block-78">
                  <div className="text-block-185">
                    Type in the name of a web domain you wish to block{" "}
                  </div>
                  <input
                    type="text"
                    className="text-field w-input"
                    autoFocus="true"
                    maxLength={256}
                    name="Website"
                    data-name="Website"
                    placeholder="e.g. www.unwantedsite.com"
                    id="Website"
                    required=""
                    onClick={clearBlockData}
                    onChange={(e) => setSelectedBlockValue(e.target.value)}
                    value={blockValue.option3}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <button
          disabled={!getSelectedBlockValue()}
          className={`block-domain-button w-button ${
            !getSelectedBlockValue() ? "disabledbutton" : ""
          }`}
          onClick={handleBlockItClicked}
        >
          Block it
        </button>
        <div>
          {blockStatus?.type === "success" && (
            <div className="w-form-submitted">{blockResponse}</div>
          )}
          {blockStatus?.type === "unChanged" && (
            <div className="w-form-fail">{blockMessage}</div>
          )}
          {blockStatus?.type === "error" && (
            <div className="w-form-fail">
              Oops! Something went wrong while submitting the form.
            </div>
          )}
        </div>
      </div>
    );
  };

  const handleBlockItClicked = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowPopup("block");
  };

  const handleUnblockItClicked = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowPopup("unblock");
  };

  const renderUnblockOptions = () => {
    return (
      <div
        data-w-tab="Unblock Web Domains"
        className="w-tab-pane w--tab-active "
      >
        <div className="div-block-80">
          <h3 className="heading-69">
            Select an option to unblock a web domain
          </h3>
          <div
            data-current="Option 1"
            data-easing="ease"
            data-duration-in={300}
            data-duration-out={100}
            className="unblock-options-tab w-tabs"
          >
            <div className="tabs-menu-7 w-tab-menu ">
              <button
                type="button"
                data-w-tab="Option 1"
                className={`tab-link-option-1 w-inline-block w-tab-link ${
                  unblockOption === "Option 1" ? "w--current" : ""
                }`}
                onClick={() => setUnBlockOption("Option 1")}
              >
                <div>Option 1</div>
              </button>
              <button
                type="button"
                data-w-tab="Option 2"
                className={`tab-link-option-2 w-inline-block w-tab-link ${
                  unblockOption === "Option 2" ? "w--current" : ""
                }`}
                onClick={() => setUnBlockOption("Option 2")}
              >
                <div className="text-block-207">Option 2</div>
              </button>
            </div>
            <div className="tabs-content-6 w-tab-content">
              {unblockOption === "Option 1" && (
                <div data-w-tab="Option 1" className="w-tab-pane w--tab-active">
                  <label htmlFor="unblock-website" className="field-label-3">
                    Select a domain from the list to unblock{" "}
                  </label>

                  {blockData !== undefined ? (
                    <select
                      className="select-field w-select"
                      onChange={(e) => setSelectedUnblockValue(e.target.value)}
                      value={getSelectedUnblockValue()}
                    >
                      <option key="" value="">
                        Select a domain
                      </option>
                      {blockData.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <select className="select-field w-select">
                      <option key="" value="">
                        No Data
                      </option>
                    </select>
                  )}
                </div>
              )}
              {unblockOption === "Option 2" && (
                <div
                  data-w-tab="Option 2"
                  className="tab-pane-option-2 w-tab-pane w--tab-active"
                >
                  <div className="div-block-73">
                    <div className="text-block-187">
                      Type in the name of the domain you wish to unblock{" "}
                    </div>
                    <input
                      type="text"
                      className="text-field-2 w-input"
                      maxLength={256}
                      name="unblock-website"
                      data-name="unblock website"
                      placeholder="e.g. www.needthissite.com"
                      id="unblock-website"
                      required=""
                      onClick={clearUnBlockData}
                      onChange={(e) => setSelectedUnblockValue(e.target.value)}
                      value={getSelectedUnblockValue()}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <button
            disabled={!getSelectedUnblockValue()}
            className={`unblock-domain-button w-button ${
              !getSelectedUnblockValue() ? "disabledbutton" : ""
            }`}
            onClick={handleUnblockItClicked}
          >
            Unblock it
          </button>
        </div>
        <div>
          {unBlockstatus?.type === "success" && (
            <div className="w-form-submitted">{UnblockResponse}</div>
          )}
          {unBlockstatus?.type === "unChanged" && (
            <div className="w-form-fail">{unBlockMessage}</div>
          )}
          {unBlockstatus?.type === "error" && (
            <div className="w-form-fail">
              Oops! Something went wrong while submitting the form.
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="app-buttons-section wf-section">
        <div className="container w-container">
          <div className="div-block-32">
            <div className="lock-image">
              <img
                src="images/icons_8.png"
                loading="lazy"
                align-item={"center"}
                alt=""
              />
            </div>
            <h1 className="heading-24 blockWebsitesTitle">
              Block/Unblock Web Domains
            </h1>

            <div className="form-block-2 w-form">
              <form
                id="wf-form-Block-Website-Block"
                name="wf-form-Block-Website-Block"
                data-name="Block Website Block"
                method="get"
                className="form-2"
                data-wf-page-id="64d10eeb4bfcea1e4b14e2c0"
                data-wf-element-id="f5af7f95-1dc4-0687-ff73-8529c62f9702"
              >
                <div
                  data-current="Block Web Domains"
                  data-easing="ease"
                  data-duration-in={300}
                  data-duration-out={100}
                  className="block-unblock-tab w-tabs"
                >
                  <div className="block-unblock-tab-menu w-tab-menu">
                    <button
                      type="button"
                      data-w-tab="Block Web Domains"
                      className={`tab-link-block-web-domains w-inline-block w-tab-link ${
                        selectedValue === "block" ? "w--current" : ""
                      }`}
                      onClick={() => setSelectedValue("block")}
                    >
                      <div className="text-block-212">BLOCK</div>
                    </button>
                    <button
                      type="button"
                      data-w-tab="Unblock Web Domains"
                      className={`tab-link-unblock-web-domains w-inline-block w-tab-link ${
                        selectedValue === "unblock" ? "w--current" : ""
                      }`}
                      onClick={() => setSelectedValue("unblock")}
                    >
                      <div>UNBLOCK</div>
                    </button>
                  </div>
                  <div className="block-unblock-tab-content w-tab-content">
                    {selectedValue === "block"
                      ? renderBlockOptions()
                      : renderUnblockOptions()}
                  </div>
                </div>
              </form>
            </div>
            <div className="text-block-189">
              <p>
                <strong className="bold-text-117">Warning:</strong> Blocking
                specific domains may cause apps, devices or websites that use
                that domain to not function properly.{" "}
              </p>
              <Link to="/dashboard" className="button-7 w-button">
                Back to Dashboard
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="wf-section">
        <div className="w-row">
          <div className="w-col w-col-4" />
          <div className="w-col w-col-4">
            <div className="text-block-30">
              @Copyright 2022, Nandi Security, Inc
            </div>
          </div>
          <div className="w-col w-col-4" />
        </div>
      </div>
      <CustomPopup show={showPopup} onClose={handleOnClose}>
        <div>
          <div>
            <h1 className="heading-52">Warning</h1>
            <div className="text-block-185">
              e
              {`Are you sure you want to ${showPopup} "${
                showPopup === "block"
                  ? getSelectedBlockValue()
                  : getSelectedUnblockValue()
              }" across all devices?`}
            </div>
          </div>
          <div className="div-block-48-confirm">
            <button
              type="button"
              onClick={showPopup === "block" ? blockDomain : unBlockDomain}
              className="button-21-confirm w-button"
            >
              Yes
            </button>
            <button
              type="button"
              onClick={(e) => setShowPopup(null)}
              className="button-20-confirm w-button"
            >
              Cancel
            </button>{" "}
          </div>
        </div>
      </CustomPopup>
    </div>
  );
}

export default BlockWebsites;
