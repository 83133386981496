import React, { useEffect, useState } from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from "pure-react-carousel";
import { getDpaData } from "../api";
import "pure-react-carousel/dist/react-carousel.es.css";
import { useNavigate, Link } from "react-router-dom";
import { PiSpinner } from "react-icons/pi";

function DeepPrivacyAnalysis(props) {
  const [error, setError] = useState(false); //eslint-disable-line
  const [spinner, setSpinner] = useState(true);
  const [dpaData, setDpaData] = useState("");
  const [selectedValue, setSelectedValue] = useState("Personal Data");
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (props.product !== "kavalan") {
      navigate("/Dashboard");
    }
    getDpaData()
      .then((response) => {
        setDpaData(response);
        setSpinner(false);
      })
      .catch((error) => {
        setError(true);
      });
  }, []);

  const BrowsingHistory = () => {
    const listItems =
      dpaData.browsingHistory !== undefined &&
      dpaData.browsingHistory.length !== 0 ? (
        dpaData.browsingHistory?.map((link) => (
          <div>
            <button
              className="unbreached-text-block"
              onClick={() => openPrivacyPolicy(link.privacyPolicy)}
              key={link.appSeller}
            >
              {link.appSeller}{" "}
            </button>
          </div>
        ))
      ) : (
        <div className="div-block-53"> No Data </div>
      );
    return listItems;
  };
  const GeoLocation = () => {
    const listItems =
      dpaData.geoLocation !== undefined && dpaData.geoLocation.length !== 0 ? (
        dpaData.geoLocation?.map((link) => (
          <div>
            <button
              className="unbreached-text-block"
              onClick={() => openPrivacyPolicy(link.privacyPolicy)}
              key={link.appSeller}
            >
              {link.appSeller}{" "}
            </button>
          </div>
        ))
      ) : (
        <div className="div-block-53"> No Data </div>
      );
    return listItems;
  };
  const SearchHistory = () => {
    const listItems =
      dpaData.searchHistory !== undefined &&
      dpaData.searchHistory.length !== 0 ? (
        dpaData.searchHistory?.map((link) => (
          <div>
            <button
              className="unbreached-text-block"
              onClick={() => openPrivacyPolicy(link.privacyPolicy)}
              key={link.appSeller}
            >
              {link.appSeller}{" "}
            </button>
          </div>
        ))
      ) : (
        <div className="div-block-53"> No Data </div>
      );
    return listItems;
  };

  const UsageData = () => {
    const listItems =
      dpaData.usageData !== undefined && dpaData.usageData.length !== 0 ? (
        dpaData.usageData?.map((link) => (
          <div>
            <button
              className="unbreached-text-block"
              onClick={() => openPrivacyPolicy(link.privacyPolicy)}
              key={link.appSeller}
            >
              {link.appSeller}{" "}
            </button>
          </div>
        ))
      ) : (
        <div className="div-block-53"> No Data </div>
      );
    return listItems;
  };

  const Purchases = () => {
    const listItems =
      dpaData.purchases !== undefined && dpaData.purchases.length !== 0 ? (
        dpaData.purchases?.map((link) => (
          <div>
            <button
              className="unbreached-text-block"
              onClick={() => openPrivacyPolicy(link.privacyPolicy)}
              key={link.appSeller}
            >
              {link.appSeller}
            </button>
          </div>
        ))
      ) : (
        <div className="div-block-53"> No Data </div>
      );
    return listItems;
  };

  const openPrivacyPolicy = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const Identifiers = () => {
    const listItems =
      dpaData.identifiers !== undefined && dpaData.identifiers.length !== 0 ? (
        dpaData.identifiers?.map((link) => (
          <div>
            <button
              className="unbreached-text-block"
              onClick={() => openPrivacyPolicy(link.privacyPolicy)}
              key={link.appSeller}
            >
              {link.appSeller}{" "}
            </button>
          </div>
        ))
      ) : (
        <div className="div-block-53"> No Data </div>
      );
    return listItems;
  };

  const ContactInformation = () => {
    const listItems =
      dpaData.contactInfo !== undefined && dpaData.contactInfo.length !== 0 ? (
        dpaData.contactInfo?.map((link) => (
          <div>
            <button
              className="unbreached-text-block"
              onClick={() => openPrivacyPolicy(link.privacyPolicy)}
              key={link.appSeller}
            >
              {link.appSeller}{" "}
            </button>
          </div>
        ))
      ) : (
        <div className="div-block-53"> No Data </div>
      );
    return listItems;
  };

  const FinancialInformation = () => {
    const listItems =
      dpaData.financialInfo !== undefined &&
      dpaData.financialInfo.length !== 0 ? (
        dpaData.financialInfo?.map((link) => (
          <div>
            <button
              className="unbreached-text-block"
              onClick={() => openPrivacyPolicy(link.privacyPolicy)}
              key={link.appSeller}
            >
              {link.appSeller}{" "}
            </button>
          </div>
        ))
      ) : (
        <div className="div-block-53"> No Data </div>
      );
    return listItems;
  };

  const ContactList = () => {
    const listItems =
      dpaData.contactList !== undefined && dpaData.contactList.length !== 0 ? (
        dpaData.contactList?.map((link) => (
          <div>
            <button
              className="unbreached-text-block"
              onClick={() => openPrivacyPolicy(link.privacyPolicy)}
              key={link.appSeller}
            >
              {link.appSeller}{" "}
            </button>
          </div>
        ))
      ) : (
        <div className="div-block-53"> No Data </div>
      );
    return listItems;
  };

  const PersonalInfo = () => {
    return (
      <CarouselProvider
        infinite={true}
        naturalSlideWidth={50}
        // naturalSlideHeight={100}
        totalSlides={4}
      >
        <div className="mask w-slider-mask">
          <Slider>
            <Slide index={0} style={{ padding: "9rem 0%" }}>
              <div id="1" className="slide w-slide">
                <div className="div-block-61">
                  <img
                    width={100}
                    sizes="75px"
                    src="images/16.png"
                    loading="lazy"
                    alt=""
                    srcSet="images/16-p-500.png 500w, images/16-p-800.png 800w, images/16.png 1024w"
                    className="image-38"
                  />
                  <h3 className="heading-55">Identifiers</h3>
                  <div className="headingclass">
                    <h5 className="heading-64">
                      Identifiers may include first name, last name, user ids
                      and any other unique identifier used to identify you such
                      as a user name. <br />
                      Scroll down to see all companies that may have collected
                      or created identifiers for you
                    </h5>
                  </div>
                </div>

                <div className="div-block-53">
                  <Identifiers />
                </div>
              </div>
            </Slide>
            <Slide index={1} style={{ padding: "9rem 0%" }}>
              <div className="slide-7 w-slide">
                <div className="div-block-65">
                  <img
                    width={75}
                    height={75}
                    src="images/18.png"
                    loading="lazy"
                    alt=""
                    srcSet="images/18-p-500.png 500w, images/18-p-800.png 800w, images/18.png 1024w"
                    sizes="75px"
                    className="image-40"
                  />
                  <h3 className="heading-56">Contact Information</h3>
                  <div className="text-block-162">
                    Contact information may include email addresses, phone
                    numbers, social media handles, physical addresses.
                  </div>
                  <div className="div-block-54">
                    <FinancialInformation />
                  </div>
                </div>
              </div>
            </Slide>
            <Slide index={2} style={{ padding: "9rem 0%" }}>
              <div className="slide-8 w-slide">
                <div className="div-block-66">
                  <img
                    width={75}
                    height={75}
                    src="images/19.png"
                    loading="lazy"
                    alt=""
                    srcSet="images/19-p-500.png 500w, images/19-p-800.png 800w, images/19.png 1024w"
                    sizes="75px"
                  />

                  <h3 className="heading-57">Financial Information</h3>
                  <div className="text-block-163">
                    Financial info may include credit card numbers, financial
                    account numbers
                  </div>
                </div>
                <div className="div-block-55">
                  <ContactInformation />
                </div>
              </div>
            </Slide>

            <Slide index={3} style={{ padding: "9rem 0%" }}>
              <div className="slide-9 w-slide">
                <div className="div-block-67">
                  <img
                    width={75}
                    height={75}
                    src="images/17.png"
                    loading="lazy"
                    alt=""
                    srcSet="images/17-p-500.png 500w, images/17-p-800.png 800w, images/17.png 1024w"
                    sizes="75px"
                  />
                  <h3 className="heading-58">Contact List</h3>
                  <div className="text-block-164">
                    Apps, websites, services that may have access to your
                    contact list across multiple devices
                  </div>

                  <div className="div-block-56">
                    <ContactList />
                  </div>
                </div>
              </div>
            </Slide>
          </Slider>
        </div>
        <div className="left-arrow w-slider-arrow-left">
          <ButtonBack className="icon-3 w-icon-slider-left" />
        </div>

        <div className="right-arrow w-slider-arrow-right">
          <ButtonNext className="icon-2 w-icon-slider-right" />
        </div>
        <DotGroup
          className="slide-nav w-slider-nav w-shadow  w-round"
          slide="2"
        />
      </CarouselProvider>
    );
  };
  const BehavioralInfo = () => {
    return (
      <CarouselProvider
        naturalSlideWidth={50}
        naturalSlideHeight={20}
        totalSlides={5}
        infinite={true}
      >
        <div className="w-slider-mask">
          <Slider>
            <Slide index={0} style={{ padding: "9rem 0%" }}>
              {" "}
              <div className="slide-2 w-slide">
                <div className="div-block-68">
                  <img
                    width={75}
                    sizes="75px"
                    src="images/20.png"
                    loading="lazy"
                    alt=""
                  />
                  <h3 className="heading-59">Browsing History</h3>
                  <div className="div-block-58">
                    <BrowsingHistory />
                  </div>
                </div>
              </div>
            </Slide>
            <Slide index={1} style={{ padding: "9rem 0%" }}>
              <div className="slide-3 w-slide">
                <div className="div-block-69">
                  <img
                    width={75}
                    height={75}
                    src="images/21.png"
                    loading="lazy"
                    alt=""
                    srcSet="images/21-p-500.png 500w, images/21-p-800.png 800w, images/21.png 1024w"
                    sizes="75px"
                  />
                  <h3 className="heading-59">Geo Location</h3>
                  <div className="div-block-58">
                    <GeoLocation />
                  </div>
                </div>
              </div>
            </Slide>
            <Slide index={2} style={{ padding: "9rem 0%" }}>
              {/* <div className="w-slider-mask"> */}
              <div className="slide-4 w-slide">
                <div className="div-block-72">
                  <img
                    width={75}
                    height={75}
                    src="images/search.jpg"
                    loading="lazy"
                    alt=""
                    srcSet="images/search-p-500.jpg 500w, images/search.jpg 512w"
                    sizes="75px"
                  />
                  <h3 className="heading-61">Search History</h3>
                  <div className="div-block-58">
                    <SearchHistory />
                  </div>
                  {/* </div>{" "} */}
                </div>
              </div>
            </Slide>

            <Slide index={3} style={{ padding: "9rem 0%" }}>
              <div className="slide-5 w-slide">
                <div className="div-block-71">
                  <img
                    width={75}
                    height={75}
                    src="images/22.png"
                    loading="lazy"
                    alt=""
                    srcSet="images/22-p-500.png 500w, images/22-p-800.png 800w, images/22.png 1024w"
                    sizes="75px"
                  />
                  <h3 className="heading-62">Usage Data</h3>
                  <div className="div-block-58">
                    <UsageData />
                  </div>
                </div>
              </div>
            </Slide>
            <Slide index={4} style={{ padding: "8rem 0%" }}>
              <div className="slide-6 w-slide">
                <div className="div-block-70">
                  <img
                    width={75}
                    height={75}
                    src="images/23.png"
                    loading="lazy"
                    alt=""
                    srcSet="images/23-p-500.png 500w, images/23-p-800.png 800w, images/23.png 1024w"
                    sizes="75px"
                  />
                  <h3 className="heading-63">Purchases</h3>
                  <div className="div-block-58">
                    <Purchases />
                  </div>
                </div>
              </div>
            </Slide>
          </Slider>
        </div>
        <div className="left-arrow-2 w-slider-arrow-left">
          <ButtonBack className="icon-5 w-icon-slider-left" />
        </div>
        <div className="right-arrow-2 w-slider-arrow-right">
          <ButtonNext className="icon-4 w-icon-slider-right" />
        </div>

        <div>
          <DotGroup className="slide-nav w-slider-nav w-shadow  w-round" />
        </div>
      </CarouselProvider>
    );
  };

  // Unused function delete after 12/31/2024
  // const FetchingSpinner = () => {
  //   return (
  //     <div class="waveTextAnimatedDashboard">
  //       <span>Fetching Data</span>
  //       <span>.</span>
  //       <span>.</span>
  //       <span>.</span>
  //     </div>
  //   );
  // };

  return (
    <div>
      <h1 className="heading-54">Data Exposure Risk Report</h1>
      <div className="text-block-183">
        Review what personal and behavioral information may have been accessed
        or you may have given the right to access based on the websites or apps
        that you may have used in the last 24 hours
      </div>
      <div className="privacy-analysis-section">
        <div className="container-4 w-container">
          {spinner === true ? (
            <div className="pageload-spinner">
              {" "}
              {/* <FetchingSpinner />{" "} */}
              <PiSpinner
                style={{
                  fontSize: "3rem",
                  animation: "spin 2s linear infinite",
                }}
              />
            </div>
          ) : (
            ""
          )}
          <div
            data-current=""
            data-easing="ease"
            data-duration-in={300}
            data-duration-out={100}
            className="tabs-6 w-tabs"
          >
            <div className="tabs-menu-9 w-tab-menu">
              <button
                type="button"
                data-w-tab="Personal Information"
                className={`tab-link-personal-information w-inline-block w-tab-link  ${
                  selectedValue === "Personal Data" ? "w--current" : ""
                }`}
                onClick={() => setSelectedValue("Personal Data")}
              >
                <div className="text-block-217">Personal Data</div>
              </button>
              <button
                type="button"
                data-w-tab="Behavioral Information"
                className={`tab-link-behavioral-information w-inline-block w-tab-link  ${
                  selectedValue === "behavioral data" ? "w--current" : ""
                }`}
                onClick={() => setSelectedValue("behavioral data")}
              >
                <div className="text-block-218">behavioral data</div>
              </button>
            </div>
            <div className="tabs-content-8 w-tab-content">
              {selectedValue === "Personal Data" ? (
                <div
                  data-w-tab="Personal Information"
                  className="w-tab-pane w--tab-active"
                >
                  <div className="personal-info-div">
                    <div className="text-block-219">
                      Companies that may have tracked the online behaviors of
                      your household members in the last 24 hours based on your
                      use of their app, device or website in your home.* Click
                      on a company to read their privacy policy.
                    </div>
                    <div className="div-block-51">
                      <div
                        data-delay={4000}
                        data-animation="slide"
                        className="slider w-slider"
                        data-autoplay="false"
                        data-easing="ease"
                        data-hide-arrows="true"
                        data-disable-swipe="false"
                        data-autoplay-limit={0}
                        data-nav-spacing={3}
                        data-duration={500}
                        data-infinite="true"
                      >
                        <PersonalInfo />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  data-w-tab="Behavioral Information"
                  className="w-tab-pane w--tab-active"
                >
                  <div className="behavioral-div">
                    <div className="text-block-220">
                      Companies that may have tracked the online behaviors of
                      your household members in the last 24 hours based on your
                      use of their app, device or website in your home.* Click
                      on a company to read their privacy policy.
                    </div>
                    <div
                      data-delay={4000}
                      data-animation="slide"
                      className="slider-2 w-slider"
                      data-autoplay="false"
                      data-easing="ease"
                      data-hide-arrows="true"
                      data-disable-swipe="false"
                      data-autoplay-limit={0}
                      data-nav-spacing={3}
                      data-duration={500}
                      data-infinite="true"
                    >
                      <BehavioralInfo />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <h2 className="heading-83">What can you do with this information?</h2>
          <h3 className="heading-84">
            Review the privacy policy to see exactly what they collect, when
            they collect and how they use your information. Companies may
            provide 'do not collect', 'do not sell my information' or 'delete my
            information' options that you can take advantage of.
          </h3>
          <Link to="/dashboard" className="button-26 w-button">
            Back to dashboard
          </Link>
        </div>
      </div>

      <div className="text-block-181">
        *Disclaimer: This is an estimation of your privacy risk based on the
        internet behavior of devices in your home in the last 24 hours and the
        privacy disclosures of companies that Kavalan is aware of. This may not
        be an accurate reflection of the actual data that may have been exposed
        to these companies, which may vary based on usage of certain features,
        app settings or other parameters decided by each company. Your home may
        have also interacted with companies that Kavalan may not have prior
        intelligence on and hence may not include in the privacy risk analysis.
        To understand the full extent of the privacy risk each of the companies
        identified, please review the privacy policy of each of the companies.
      </div>
    </div>
  );
}

export default DeepPrivacyAnalysis;
