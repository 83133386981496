export const ACCESS_TOKEN = "accessToken";
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const OAUTH2_REDIRECT_URI = window.location.origin + "/oauth2/redirect";
export const GOOGLE_AUTH_URL =
  API_BASE_URL +
  "/api/oauth2/authorize/google?redirect_uri=" +
  OAUTH2_REDIRECT_URI;
console.log(GOOGLE_AUTH_URL);
export const FACEBOOK_AUTH_URL =
  API_BASE_URL +
  "/api/oauth2/authorize/facebook?redirect_uri=" +
  OAUTH2_REDIRECT_URI;
export const STRIPE_PUBLISH_KEY = process.env.REACT_APP_STRIPE_PUB_KEY;
export const PRIMARY_DNS = process.env.REACT_APP_PRIMARY_DNS_SERVER;
